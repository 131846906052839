$(document).on 'ready', ->
  togglePointFields false
  setupEditor()

$(document).on 'change', '#admin-radar #radar_radar_template_id', ->
  $('#radar_config').val '{"categories":[],"data":[]}'
  togglePointFields false
  setupEditor()

updateTable = (config) ->
  $table = $('#admin-radar-points-table')
  $thead = $table.children('thead')
  $tr = $thead.children('tr')
  $tr.children('th').slice(2, -1).remove()
  for cat in config.categories
    $th = $('<th class="control-cell"/>')
    $th.append(cat.name)
    $controls = $("<div class=\"controls\" data-cat=\"#{cat.id}\"/>")
    $controls.append($('<a href="javascript:;" class="left-button"/>').append('<'))
    $controls.append($('<a href="javascript:;" class="edit-button"/>').append($('<span class="icon" data-icon="\ue91d"/>')))
    $controls.append($('<a href="javascript:;" class="delete-button"/>').append($('<span class="icon" data-icon="\ue91c"/>')))
    $controls.append($('<a href="javascript:;" class="right-button"/>').append('>'))
    $th.append($controls)
    $tr.children().last().before($th)
  $tbody = $table.children('tbody')
  $tbody.empty()
  for row in config.data.sort((a, b) -> a.info.number - b.info.number)
    $tr = $('<tr/>')
    $tr.append($('<th/>').append(row.info.number))
    $tr.append($('<td/>').append(row.info.name))
    for cat in config.categories
      $checkbox = $("<input type=\"checkbox\" data-id=\"#{row.info.id}\" data-cat=\"#{cat.id}\"/>")
      if row.categories && row.categories.indexOf(cat.id) >= 0
        $checkbox.attr('checked', true)
      $tr.append($('<td class="control-cell"/>').append($checkbox))
    $tr.append($('<td/>'))
    $tbody.append($tr)

togglePointFields = (enabled) ->
  $nameField = $('#point_name')
  $urlField = $('#point_url')
  $messageField = $('#point_message')
  $removeButton = $('#point_remove')
  $nameField.val null if !enabled
  $nameField.prop 'disabled', !enabled
  $urlField.val null if !enabled
  $urlField.prop 'disabled', !enabled
  $messageField.val null if !enabled
  $messageField.prop 'disabled', !enabled
  $removeButton.prop 'disabled', !enabled

renumberData = (data) ->
  data.sort((a, b) -> a.value[0] - b.value[0] || a.value[1] - b.value[1]).forEach((o, i) -> o.info.number = i + 1)

setupEditor = ->
  $radar = $('#admin-radar .chart-editor')
  return unless $radar.length
  $templateInput = $('#radar_radar_template_id')
  $configInput = $('#radar_config')
  $nameField = $('#point_name')
  $urlField = $('#point_url')
  $messageField = $('#point_message')
  $removeButton = $('#point_remove')
  $table = $('#admin-radar-points-table')
  $addCategory = $('#admin-radar-add-category')
  template = $templateInput.children(':selected').data('config')
  config = JSON.parse($configInput.val())
  updateTable(config)
  celentRadar $radar.get(0), template, config, (chart) ->
    selectedPoint = null
    cs = chart.getModel().getComponent('polar').coordinateSystem
    chart.setOption {
      series: [
        {
          itemStyle: {
            color: (params) -> if params.dataIndex == config.data.indexOf(selectedPoint) then '#f66' else '#ccc',
          },
          id: 'data',
        }
      ]
    }
    chart.getZr().on 'mousedown', (params) ->
      return unless params.event.button == 0
      if !params.target
        pos = cs.pointToData([params.offsetX, params.offsetY])
        if pos[0] > template.regions.length * 1.2
          togglePointFields false
          selectedPoint = null
          chart.setOption { series: [{ data: config.data, id: 'data' }] }
          return
        id = Math.max(0, config.data.map((o) -> o.info.id)...) + 1
        num = Math.max(0, config.data.map((o) -> o.info.number)...) + 1
        point = {value: pos, info: {id: id, number: num, name: 'New point'}}
        config.data.push(point)
        renumberData(config.data)
        updateTable(config)
        selectedPoint = point
      else
        return unless params.target.seriesIndex == 0
        selectedPoint = config.data[params.target.dataIndex]
      chart.setOption { series: [{ data: config.data, id: 'data' }] }
      $nameField.val selectedPoint.info.name
      $urlField.val selectedPoint.info.url
      $messageField.val selectedPoint.info.message
      togglePointFields true
      updateTable(config)
      setTimeout (-> $nameField.focus()), 0
    chart.getZr().on 'mousemove', (params) ->
      return unless selectedPoint && params.event.buttons & 1
      pos = cs.pointToData([params.offsetX, params.offsetY])
      selectedPoint.value = pos
      renumberData(config.data)
      updateTable(config)
      chart.setOption { series: [{ data: config.data, id: 'data' }] }
    $nameField.on 'input', (e) ->
      selectedPoint.info.name = $nameField.val()
      updateTable(config)
      chart.setOption { series: [{ data: config.data, id: 'data' }] }
    $urlField.on 'input', (e) ->
      selectedPoint.info.url = $urlField.val()
      chart.setOption { series: [{ data: config.data, id: 'data' }] }
    $messageField.on 'input', (e) ->
      selectedPoint.info.message = $messageField.val()
      chart.setOption { series: [{ data: config.data, id: 'data' }] }
    $removeButton.on 'click', ->
      return unless selectedPoint
      config.data.splice(config.data.indexOf(selectedPoint), 1)
      togglePointFields false
      selectedPoint = null
      renumberData(config.data)
      updateTable(config)
      chart.setOption { series: [{ data: config.data, id: 'data' }] }
    $addCategory.on 'click', ->
      name = window.prompt('Category name')
      if name
        id = Math.max(0, config.categories.map((o) -> o.id)...) + 1
        config.categories.push({id: id, name: name})
      updateTable(config)
    $table.on 'click', ':checkbox', ->
      $checkbox = $(this)
      id = parseInt($checkbox.data('id'), 10)
      catId = parseInt($checkbox.data('cat'), 10)
      row = config.data.find((o) -> o.info.id == id)
      row.categories ||= []
      if $checkbox.prop('checked')
        row.categories.push(catId)
        row.categories.sort()
      else
        row.categories = row.categories.filter((i) -> i != catId)
    $table.on 'click', '.edit-button', ->
      catId = parseInt($(this).parent().data('cat'), 10)
      cat = config.categories.find((o) -> o.id == catId)
      name = window.prompt('Category name', cat.name)
      if name
        cat.name = name
        updateTable(config)
    $table.on 'click', '.delete-button', ->
      if window.confirm('Are you sure you want to delete this category?')
        catId = parseInt($(this).parent().data('cat'), 10)
        config.categories.splice(config.categories.findIndex((o) -> o.id == catId), 1)
        for row in config.data
          if row.categories
            row.categories = row.categories.filter((i) -> i != catId)
      updateTable(config)
    $table.on 'click', '.left-button', ->
      catId = parseInt($(this).parent().data('cat'), 10)
      i = config.categories.findIndex((o) -> o.id == catId)
      return if i == 0
      [cat] = config.categories.splice(i, 1)
      config.categories.splice(i - 1, 0, cat)
      updateTable(config);
    $table.on 'click', '.right-button', ->
      catId = parseInt($(this).parent().data('cat'), 10)
      i = config.categories.findIndex((o) -> o.id == catId)
      return if i == config.categories.length - 1
      [cat] = config.categories.splice(i, 1)
      config.categories.splice(i + 1, 0, cat)
      updateTable(config);
    $('form').on 'submit', ->
      $configInput.val JSON.stringify(config)
