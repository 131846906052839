
$(document).ready ->
  insightSetupCrop()
  $page_content_height = $("#profile-page-content").innerHeight()
  if window.innerWidth < 1090
    $("#profile-page-links").innerHeight(10)
  # else   
  #   $("#profile-page-links").innerHeight($page_content_height)

$(window).on "resize", -> 
  if window.innerWidth < 1090
    $("#profile-page-links").innerHeight(10)
  # else   
  #   $("#profile-page-links").innerHeight($page_content_height)


insightSetupCrop = ->
  $('#insight-logo-input').cropSource $('#insight-logo'), 250, 150
