$(document).ready ->
  $all = $('#category-all')
  return unless $all.length
  # setup carousel (if it isn't already)
  $('#category-adverts .adverts-carousel').each (i, carousel) ->
    categorySetupCarousel($(carousel))

categorySetupCarousel = ($carousel) ->
  if $carousel.css('position') == 'relative'
    $carousel.find('img').each (i, img) ->
      $img = $(img)
      $img.attr('src', $img.attr('src'))
  else
    $carousel.jCarouselLite(speed: 0, visible: 1, swipe: false, afterEnd: (li) ->
      $li = $(li)
      $img = $li.find('img')
      $img.attr('src', $img.attr('src'))
      setTimeout (-> $carousel.trigger('go')), if $li.data('loop_length') then parseInt($li.data('loop_length')) else 15000
    )
    $li = $carousel.children('ul').children('li.first')
    setTimeout (-> $carousel.trigger('go')), if $li.data('loop_length') then parseInt($li.data('loop_length')) else 15000
