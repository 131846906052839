$(document).ready  ->
  elements = document.getElementsByClassName "myGraphs"
  for i in [0..(elements.length - 1)]
    graph_html = elements[i]
    if graph_html
      ctx = graph_html.getContext "2d"
      data = JSON.parse ctx.canvas.dataset.data
      options = JSON.parse ctx.canvas.dataset.options
      myChart = new Chart(ctx, {type: ctx.canvas.dataset.type, data: data, options: options} )

$(document).on 'change', '.model-form-group input:checkbox, .model-form-group select', ->
  $group = $(this).closest('.model-form-group')
  $topics = $group.find('.model-form-topic')
  $selectedTopics = $group.find('input:checked, option[value!=""][value!="false"]:selected').closest('.model-form-topic')
  selectedIds = ($selectedTopics.map (i, t) -> '' + $(t).data('id')).get()
  $visibleTopics = $topics.filter (i, t) ->
    deps = $(t).data('deps')
    return true unless deps
    ('' + deps).split(' ').some (i) -> selectedIds.indexOf(i) > -1
  $visibleTopics.slideDown()
  $topics.not($visibleTopics).slideUp()

$(document).on 'click', '.form-group-private-info-button', ->
  $area = $(this).closest('.form-group').find('.private-info')
  $area.slideToggle(-> $area.val(''))

$(document).ready ->
  $("#sub_pro_level").on "change", ->
    $.ajax({
      type : "GET",
      url : "/admin/subs/visualiser_callback/" + this.value,
      dataType: 'script',
      format: 'js',
    })

$(document).on "click", "#move-button", (evt) ->
  $div = document.getElementById("move-company");
  if ($div.hidden == false)
    $div.hidden = true
  else
    $div.hidden = false

$(document).ready ->
  $("#move-surveyable-input").on "keydown", (e) ->
    $select = $("#move-companies")
    if (e.key != "Backspace")
        value = this.value + e.key
      else
        value = this.value.slice(0, -1)
    if (value.length > 2)
      $.ajax "/admin/companies/update_company_list",
        type : "GET",
        data : {"value" : value},
        success: (data) ->
          $select.empty()
          for x in data.companies
            $select.append('<option value=' + x[1] + '>' + x[0] + '</option>');
    else
    $select.empty()
    $('#move-save-button').attr('disabled','disabled');

$(document).on "change", "#move-companies", (evt) ->
    selectVal = $('#move-companies').val();
    if (selectVal == 0)
      $('#move-save-button').attr('disabled','disabled');
    else 
      $('#move-save-button').removeAttr('disabled');
      

    