$(document).ready ->
  productSetupCrop()

productSetupCrop = ->
  $('#product-logo-input').cropSource $('#product-logo'), 250, 150

$(document).on 'click', '#product-suggestion a', ->
  p_width = 750
  p_height = 500
  p_left = (screen.width - p_width) / 2
  p_top = (screen.height - p_height) / 2
  window.open(this.href, 'Suggest Amendments', "resizable=true,left=#{p_left},top=#{p_top},width=#{p_width},height=#{p_height}")
  false
