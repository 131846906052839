require("slick-carousel")
removeHeadeHeight = ->
  $headerContainers = $(".recent-insight-header")
  for el in $headerContainers
    $(el).height('auto')  

recalcHeaderHeight = ->
  $headers = $(".insight-title")
  $headerContainers = $(".recent-insight-header")
  $height = 0
  for el in $headers
    if el.offsetHeight > $height
      $height = el.offsetHeight
  for el in $headerContainers
    $(el).height($height)  

$(document).ready ->
  testing = $(".recent-insight-header")
  if $(window).width() > 776
    recalcHeaderHeight()
  #   recalcHeaderHeight()

  $('.carousel').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      dots: true,
      centerMode: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            centerMode: true,
            centerPadding: '0px',
            slidesToShow: 3
          }
        }, 
        {
          breakpoint:1000,
          settings: {
            centerMode: true,
            centerPadding: '15%',
            slidesToShow: 2
          }
        }, 
        {
          breakpoint:600,
          settings: {
            centerMode: true,
            centerPadding: '15%',
            slidesToShow: 1
          }
        }
      ]
  });

  $('.carousel-type').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      centerPadding: '60px'
      dots: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {

            centerPadding: '30px',
            slidesToShow: 2
          }
        }, 
        {
          breakpoint:600,
          settings: {
            centerPadding: '40px',
            slidesToShow: 1
          }
        }
      ]
  });


$(window).resize ->
  if $(this).width() > 976
    recalcHeaderHeight()
  else 
    removeHeadeHeight()  

$(document).on 'click', '#loadMore', (e) ->
  cat_id = window.location.href.split('/')[window.location.href.split('/').length - 1]
  val = $("#vendor_count")[0].value
  all_company_ids = JSON.parse(e.target.value)
  count = +val + 10
  if count >= all_company_ids.length
    $(e.target).hide()
  $.ajax(
    url:"/category_hubs/#{cat_id}?vendor_count=#{JSON.stringify(all_company_ids.slice(0, count))}",
    dataType:"script",
    method:"GET"
  )

    