$(document).on 'submit', '#admin-curation_items-queue form', (event) ->
  $this = $(this)
  $li = $this.closest('li')
  $ul = $li.closest('ul')
  $.post $this.attr('action'), $this.serialize(), ->
    $.get window.location.href, null, (data) ->
      $(data.items_html).filter('li').each (i, li) ->
        $li = $(li)
        unless $ul.children("li[data-id='#{$li.data('id')}']").length
          $ul.append($li)
    , 'json'
  , 'script'
  $li.slideUp ->
    $li.remove()
  event.preventDefault();

$(document).on 'click', '#admin-curation_items-pull', ->
  $this = $(this)
  return if $this.hasClass('pulling')
  $this.addClass('pulling')
  $trs = $('#admin-curation_items-feeds table tbody tr')
  pf = (i) ->
    if $trs[i]
      $this.text("Pulling #{i + 1} of #{$trs.length}...")
      $.post($($trs[i]).data('pull_path'), (-> pf(i + 1)))
    else
      location.reload()
  pf(0)
