$(document).on 'change', '#advert_rule_area_id', ->
  clearSelect($('#advert_rule_question_id'))
  clearSelect($('#advert_rule_section_id'))
  val = $('#advert_rule_area_id').val()
  $.get '/admin/advert_rules/select_options?' + $.param({area_id: val}), null, null, 'script' if val

$(document).on 'change', '#advert_rule_section_id', ->
  clearSelect($('#advert_rule_question_id'))
  val = $('#advert_rule_section_id').val()
  $.get '/admin/advert_rules/select_options?' + $.param({section_id: val}), null, null, 'script' if val

clearSelect = ($select) ->
  $select.val('')
  $select.children('option').slice(1).remove()
