

setFlagCookie = (key, value) ->
  document.cookie = "#{key}=#{encodeURIComponent(value)};path=/;max-age=31536000;samesite=strict;secure"

try
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
catch
  timeZone = ''
setFlagCookie('browser.timezone', timeZone)

window.XUtils = class XUtils
  @paramsHash = (str) ->
    s = str.split('?').slice(1).join('?')
    return {} unless s.length
    hash = {}
    for pair in s.split('&')
      [k, v] = pair.split('=')
      hash[k] = v
    hash

mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

# TODO remove
$(document).ready ->
  deps_any_checked = null
  $('.dep-answer').each (i, a) -> deps_any_checked or= $(a).prop('checked')
  setDependentsEnabled(false) if deps_any_checked == false # not null or true
  $('.hide-vendormatch').hide()

setDependentsEnabled = (enabled) ->
  lists = $('.answer-list').not('.answer-deps-list')
  inputs = lists.find('input:visible')
  others = lists.find('h4, label, a')
  if enabled
    inputs.prop('disabled', false)
    others.removeClass('disabled')
  else
    inputs.prop('disabled', true)
    others.addClass('disabled')

$(document).on 'click', '.dep-answer', ->
  setDependentsEnabled($(this).prop('checked'))
#end remove

$(document).on 'change', '.file_upload_field input[type="file"]', ->
  $field = $(this).closest('.file_upload_field')
  $field.children('img.attach-existing').hide()
  $field.children('img.attach-new').show()
  $field.children('input[type="file"]').hide()
  $field.children('button.clear').show()

$(document).on 'click', '.file_upload_field button.clear', ->
  $field = $(this).closest('.file_upload_field')
  $field.children('input.remove').prop('disabled', false)
  $field.children('img.attach-existing').hide()
  $field.children('img.attach-new').hide()
  $file = $field.children('input[type="file"]')
  $newFile = $file.val("").clone(true)
  $file.replaceWith($newFile)
  $newFile.show()
  $field.children('button.clear').hide()

$(document).on 'click', 'a.add_fields', ->
  $this = $(this)
  content = $('<div />').html($this.data("content")).text()
  replaceStr = content.match(/\[new_.+?\]/)[0][1..-2]
  newId = new Date().getTime()
  content = content.replace(new RegExp(replaceStr, 'g'), newId)
  $this.before(content)
  $this.prev().slideDown()
  if limit = $this.data('limit')
    $this.toggle($this.parent().children('.remove_fields-target:visible').length < limit)

$(document).on 'click', 'a.remove_fields', ->
  $this = $(this)
  $this.prev('.remove_input').find(':checkbox').prop('checked', true)
  $target = $this.closest('.remove_fields-target')
  $target.slideUp ->
    $add = $target.parent().children('a.add_fields')
    if limit = $add.data('limit')
      $add.toggle($add.parent().children('.remove_fields-target:visible').length < limit)

@EventHandlers = {}

$(document).on 'click', '.more-btn', (e) ->
  e.preventDefault()
  parent = e.target.parentElement.parentElement
  if parent.classList.value.split(" ").includes("more-btn")
    $(parent.nextElementSibling).css("display", "block")
    $(parent).hide()

$(window).load ->
  hidden_checkboxes = $('.add-to-new-project')
  if hidden_checkboxes.length > 0
    for hidden_checkbox in hidden_checkboxes
      hidden_checkbox.checked = false
  
$(document).on 'click', '.submit-form-new-project', (e) -> 
  e.preventDefault()
  form = $(this).closest("form")[0]
  hidden_checkbox = e.target.parentElement.children
  for input_i in hidden_checkbox
    if input_i.type == "checkbox" && input_i.classList[0] == 'add-to-new-project'
      input_i.checked = true
  form.submit()

$(document).on 'click', '.click-remote', ->
  form = $(this).closest('form')
  $.post form.attr('action') + '.js', form.serialize()

$(document).on 'click', '.info-link', ->
  $this = $(this)
  $this.add($this.parent()).nextAll('.info-pane').slideToggle()

$(document).on 'click', '.parent-answer', ->
  ia = $(this).nextAll('.inline-answers').first()
  if ia.size()
    ia.children('ol, ul').children('li').children('input').prop('checked', this.checked)
    ia.slideToggle()

process_event = (event) ->
  for clazz in event.target.className.split(/\s+/)
    unless clazz.indexOf('a-' + event.type + '-')
      do_info = clazz.split('-')
      if do_info[3]
        $('.' + do_info.slice(3).join('-'))[do_info[2]]()
      else
        EventHandlers[do_info[2]](event)

$(document).on 'click', '.on', process_event

$.fn.toggleDisabled = ->
  this.attr('disabled', !this.attr('disabled'))

$(document).on 'click', 'a.survey_form_protect', ->
  unchanged = true
  $form = $('form')
  $form.find('input, select, textarea').each (i, elem) ->
    $elem = $(elem)
    oldVal = if $elem.is('input:checkbox, input:radio') then !!$elem.attr('checked') else if $elem.is('input') then $elem.attr('value') or "" else if $elem.is('select') then $elem.children('option[selected="selected"]').attr('value') else $elem.text().replace(/(<.*?>|&.*?;|\W)+/g, '')
    newVal = if $elem.is('input:checkbox, input:radio') then $elem.prop('checked') else if $elem.is('textarea') then $elem.val().replace(/(<.*?>|&.*?;|\W)+/g, '') else $elem.val()
    oldVal ||= ''
    newVal ||= ''
    unchanged = oldVal == newVal # returns false and ends loop if they don't match
  if !unchanged and window.confirm("Save your changes before continuing?")
    section = XUtils.paramsHash($(this).attr('href'))["section"]
    $form.append($('<input>').attr('type', 'hidden').attr('name', 'move').val(section))
    $form.submit()
    false
  else
    true

textEditorBaseConfig =
  anchor_bottom: false
  anchor_top: false
  automatic_uploads: true
  autoresize_bottom_margin: 30
  autoresize_min_height: 200
  autoresize_overflow_padding: 8
  block_formats: 'Paragraph=p;Quote=pre;Header 3=h3;Header 4=h4'
  content_css: ['https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700', '/assets/application-bcebdd973bc84d32e975567bedf0f55d24a0bc6a4fdcab5fefd00ce471a58e8e.css']
  default_link_target: '_blank'
  images_upload_url: '/uploaded_images',
  file_picker_types: 'image'
  init_instance_callback: (editor) ->
    editor.on 'GetContent', (e) ->
      e.content = editor.getElement().value unless editor.isDirty()
  link_context_toolbar: true
  menubar: false
  relative_urls: false
  statusbar: false
  table_advtab: false
  table_appearance_options: false
  table_cell_advtab: false
  table_row_advtab: false

textEditorConfigs =
  default:
    plugins: 'autoresize lists'
    toolbar: 'undo redo | blocks | bold italic | superscript subscript | bullist numlist | hr | removeformat'
  default2:
    plugins: 'autoresize link lists'
    toolbar: 'undo redo | blocks | bold italic | superscript subscript | link | bullist numlist | hr | removeformat'
  lenient:
    plugins: 'autoresize image link lists table'
    toolbar: 'undo redo | blocks | bold italic | superscript subscript | link | image | bullist numlist | table | hr | removeformat'

$.each textEditorConfigs, (k, v) -> $.extend(v, textEditorBaseConfig);

$.fn.textEditor = ->
  $(this).each (i, t) ->
    conf = $(t).data('editor_config') || 'default'
    tinymce.init($.extend({target: t}, textEditorConfigs[conf]))

$.fn.listTree = ->
  $(this).each (i, t) ->
    $t = $(t)
    $t.find('input:checkbox:not(:checked)').closest('li').children('.list-tree-sub').hide()
    $t.on 'change', 'input:checkbox', ->
      childList = $(this).closest('li').children('.list-tree-sub')
      if this.checked
        childList.find('input:checkbox').prop('checked', true)
        childList.slideDown 400
      else
        childList.slideUp 400, -> childList.find('input:checkbox').prop('checked', false)

$.fn.listTreeSpecific = ->
  $(this).each (i, t) ->
    $t = $(t)
    $lis = $t.find('input:checkbox:checked').closest('li')
    $t.find('.list-tree-sub').not($lis.parents('.list-tree-sub').add($lis.children('.list-tree-sub'))).hide()
    $t.on 'change', 'input:checkbox', ->
      $li = $(this).closest('li')
      $childList = $li.children('.list-tree-sub')
      if this.checked
        $childList.slideDown 400
        $childList.find('.list-tree-sub').slideUp 400
        $li.closest(':not(.list-tree-split) > li').parents('li').children(':not(.list-tree-sub)').find('input:checkbox:checked').prop('checked', false)
        $childList.find('input:checkbox:checked').prop('checked', false)
      else
        $childList.slideUp 400, -> $childList.find('.list-tree-sub').hide()
        $sibs = $li.closest('.list-tree-sub').find(':not(.list-tree-sub) input:checkbox:checked')
        unless $sibs.length
          $li.closest(':not(.list-tree-split) > li').parent().closest('li').children(':not(.list-tree-sub)').find('input:checkbox').prop('checked', true)

$(document).ready ->
  $('.list-tree > ol, .list-tree > ul').listTree()
  $('.list-tree-specific > ol, .list-tree-specific > ul').listTreeSpecific()

$(document).on 'click', '.list-tree-child-controls .select-all', ->
  $(this).parent().siblings('ul').find('input[type=checkbox]').prop('checked', true)

$(document).on 'click', '.list-tree-child-controls .deselect-all', ->
  $(this).parent().siblings('ul').find('input[type=checkbox]').prop('checked', false)

$(window).load ->
  $('textarea.text-editor').textEditor()

# attach a handler to a file submit field that uploads the selected file async. and then
# opens a modal dialog with a cropping interface for the image
$.fn.cropSource = (img, targetRatio, targetRatioDenom, resizeUpload = true) ->
  return unless this.length
  tRatio = if targetRatio and targetRatioDenom then targetRatio / targetRatioDenom else targetRatio
  tRatioParam = if targetRatio and targetRatioDenom then "#{targetRatio}/#{targetRatioDenom}" else targetRatio
  controlDelete = this.parent().next()
  deleteCheck = controlDelete.find('input')
  link = $('<a href="javascript:;">remove image</a>')
  link.css('visibility', if img.attr('src') then 'visible' else 'hidden')
  link.on 'click', ->
    link.css('visibility', 'hidden')
    deleteCheck.prop('checked', true)
    img.attr('src', '')
    img.prop('alt', '')
    img.attr('style', 'display: none;')
  controlDelete.append(link)
  fileInput = this
  fileInputParent = fileInput.parent()
  this.AjaxFileUpload
    'action': '/temp_files'
    'onSubmit': ->
      'file_field': this.attr 'name'
      'authenticity_token': $('meta[name="csrf-token"]').attr('content')
      'target_ratio': if resizeUpload then tRatioParam else null
    'onComplete': (filename, response) ->
      cropAPI = null
      ratio = response.width / response.height
      # calculate dimensions of crop tool
      [wMax, hMax] = [700, 300]
      if wMax / hMax < ratio
        [w, h] = [wMax, Math.round(wMax / ratio)]
      else
        [w, h] = [Math.round(hMax * ratio), hMax]
      # function to update the preview image
      preview = (coords) ->
        $cph = $('#crop-preview-holder')
        [wPreviewMax, hPreviewMax] = [$cph.width(), $cph.height()]
        r = coords.w / coords.h
        if wPreviewMax / hPreviewMax < r
          [wPreview, hPreview] = [wPreviewMax, Math.round(wPreviewMax / r)]
        else
          [wPreview, hPreview] = [Math.round(hPreviewMax * r), hPreviewMax]
        rx = wPreview / coords.w;
        ry = hPreview / coords.h;
        $('#crop-preview-box').css(
          width: wPreview + 'px'
          height: hPreview + 'px'
          'line-height': hPreview - 2 + 'px'
        )
        $('#crop-preview').css(
          width: Math.round(rx * response.width) + 'px'
          height: Math.round(ry * response.height) + 'px'
          marginLeft: '-' + Math.round(rx * coords.x) + 'px'
          marginTop: '-' + Math.round(ry * coords.y) + 'px'
        )
      # build the crop dialog
      dialog = $('<div />', id: 'crop-dialog', class: 'clearfix')
      target = $('<img />',
        id: 'crop-target'
        src: response.url
      )
      target.attr('width', w)
      target.attr('height', h)
      targetHolder = $('<div />', id: 'crop-target-holder').html(target)
      infoHolder = $('<div />', id: 'crop-info-holder')
      previewHolder = $('<div />', id: 'crop-preview-holder')
      previewHolder.append($('<div />', id: 'crop-preview-box').append($('<img />',
        id: 'crop-preview'
        src: response.url
      )))
      infoHolder.append(previewHolder)
      buttonSave = $('<input>', type: 'submit', value: 'Save')
      buttonSave.click ->
        $.colorbox.close()
        link.css('visibility', 'visible')
        deleteCheck.prop('checked', false)
        select = cropAPI.tellSelect()
        input = fileInputParent.children('input.temp_file_input')
        input = $('<input type="hidden" class="temp_file_input">').appendTo(fileInputParent) unless input.length
        input.prop('name', fileInput.prop('name'))
        obj = {type: 'temp_file', id: response.id, x: Math.round(select['x']), y: Math.round(select['y']), w: Math.round(select['w']), h: Math.round(select['h'])}
        input.prop('value', JSON.stringify(obj))
        $holder = img.parent()
        rx = Math.round($holder.width()) / select['w'];
        ry = Math.round($holder.height()) / select['h'];
        img.attr('src', response.url).css(
          display: 'inline'
          width: Math.round(rx * response.width) + 'px'
          height: Math.round(ry * response.height) + 'px'
          marginLeft: '-' + Math.round(rx * select['x']) + 'px'
          marginTop: '-' + Math.round(ry * select['y']) + 'px'
        )
      buttonCancel = $('<input>', type: 'submit', value: 'Cancel')
      buttonCancel.click ->
        $.colorbox.close()
      previewText = $('<div />', id: 'crop-preview-text').append("<strong>Your new image will look like the preview on the left.</strong><br>Use the control above to adjust the appearance of the image.<br>Click save when you have finished.")
      buttonHolder = $('<div />', id: 'crop-button-holder').append(buttonSave).append(buttonCancel)
      infoHolder.append(previewText).append(buttonHolder)
      dialog.append(targetHolder).append(infoHolder)
      target.Jcrop(
        aspectRatio: tRatio
        bgColor: 'clear'
        onChange: preview
        onSelect: preview
        trueSize: [response.width, response.height]
        setSelect: [0, 0, response.width, response.height]
      , -> cropAPI = this)
      $.colorbox({html: dialog, closeButton: false, overlayClose: false, trapFocus: true, className: 'no-close'})

$(document).on 'click', '.answer-list :checkbox', (event) ->
  $this = $(this)
  if $this.parent().data('parent') and $this.prop('checked')
    inputs = $this.closest('.answer-list').find('input:checked:hidden')
    inputs.prop('checked', false)

$(document).ready ->
  appSetupSocialLinks()

appSetupSocialLinks = ->
  if $('.sharethis-inline-share-buttons').length
    script = document.createElement('script');
    script.setAttribute('src', '//platform-api.sharethis.com/js/sharethis.js#property=5c54304b6dbc680011d2b133&product=inline-share-buttons')
    document.body.appendChild(script)

$(document).on 'mousedown', 'a.switch-link', (event) ->
  this.href = $(this).data('href')

$(document).on 'click', 'a.lightbox-image', ->
  $.colorbox(href: this.href, maxWidth: '90%', maxHeight: '90%', photo: true)
  false

$(document).on 'click', 'ul.media_images a', ->
  $.colorbox(href: this.href, maxWidth: '90%', maxHeight: '90%', photo: true)
  false

$(document).on 'click', 'ul.media_videos a', ->
  $.colorbox(href: this.href, iframe: true, width: 640, height: 390)
  false

$(document).on 'click', 'ul.media_podcasts a', ->
  $.colorbox(href: this.href, iframe: true, width: 640, height: 390)
  false

$(document).on (if 'ontouchstart' of window then 'touchstart' else 'mousedown'), (event) ->
  $target = $(event.target)
  return if $target.is('html')
  elems = $('.hide-on-outside-click').not($target.closest('.hide-on-outside-click'))
  nh = $target.data('no-hide')
  elems = elems.not($(nh)) if nh
  popup = $target.closest('[data-popup]').data('popup')
  elems = elems.not($('#' + popup)) if popup
  elems.hide()
  $('.permissions-hover').removeClass('show-popup')
  $('.permissions-hover-solution-profile').removeClass('show-popup')

if 'ontouchstart' of window
  $(document).on
    touchstart: (event) ->
      $this = $(this)
      return true if $this.is('a')
      $popup = $('#' + $this.data('popup'))
      if $popup.is(':visible') and $this.is($popup.data('popup-trigger'))
        $popup.hide()
      else
        showPopup($this, $popup)
      event.preventDefault()
  , '.popup-trigger'
else
  $(document).on
    mousedown: (event) ->
      $this = $(this)
      return true if $this.is('a')
      $popup = $('#' + $this.data('popup'))
      if $popup.is(':visible') and $this.is($popup.data('popup-trigger'))
        $popup.hide()
      else
        showPopup($this, $popup)
      event.preventDefault()
    mouseenter: ->
      $this = $(this)
      showPopup($this, $('#' + $this.data('popup')))
    mouseleave: ->
      $('#' + $(this).data('popup')).hide()
  , '.popup-trigger'

showPopup = ($link, $popup) ->
  return unless $popup.length
  $linkOffset = $link.offset()
  $popup.show()
  left = $linkOffset.left - ($popup.outerWidth() - $link.outerWidth()) / 2
  pad = $('#app-content-inner').css('padding-left').replace('px', '')
  left = pad if left < pad
  top = $linkOffset.top + $link.outerHeight() + 5
  $window = $(window)
  if top + $popup.outerHeight() + 5 > $window.scrollTop() + $window.height()
    top = $linkOffset.top - $popup.outerHeight() - 5
  $popup.offset(left: left, top: top)
  $popup.data('popup-trigger', $link)

$(document).on 'click', '#surveyable-title-projects', ->
  $list = $('#surveyable-title-projects-list')
  if $list.find('ul').children().length == 1
    $list.find('form').submit()
  else
    $list.slideDown('fast')
  false

# workaround for IE8 not passing label clicks to hidden inputs
$(document).on 'click', '#surveyable-title-projects-list li label', (event) ->
  event.preventDefault()
  $("##{$(this).attr('for')}").trigger('click')

$(document).on 'click', '#surveyable-title-projects-list li input', (event) ->
  # use setTimeout to ensure the input is updated before the form is submitted (IE8 workaround)
  $this = $(this)
  setTimeout (-> $this.closest('form').submit()), 0

$(document).on 'click', 'a.in_popup, #colorbox a.keep_in_popup', (event) ->
  if $(window).width() >= 760 and $(window).height() >= 520
    arr = this.href.split('?')
    href = [arr[0] + '/popup'].concat(arr.slice(1)).join('?')
    $.colorbox(href: href, onComplete: (-> $('#colorbox [autofocus]').focus()))
    false
  else
    true

$(document).on 'click', 'input.location-main-check', ->
  if this.checked
    $('input.location-main-check').not($(this)).prop('checked', false)

$(document).on 'click', 'input.hide-times-trigger', ->
  $this = $(this)
  $this.closest('fieldset').toggleClass('hide-times', !$this.prop('checked'))

remoteFormSubmitHandler = (e) ->
  $form = $(this).closest('form')
  return if $form.queue().length
  $form.delay(e.data.delay) if e.data && e.data.delay

  $form.queue (next) ->
    $input = $(this).find('input')
    if $input[1]
      if($input[1].value.match(mailformat))
        val = $input[1].value
        val = val.replace(/\..*/,"")
        $input[1].value = val
    $form.submit()
    next()

$(document).on 'keyup', '.filter-table > form input:text', delay: 500, remoteFormSubmitHandler
$(document).on 'change', '.filter-table > form input:checkbox', remoteFormSubmitHandler
$(document).on 'change', '.filter-table > form select', remoteFormSubmitHandler
$(document).on 'change', '.analysis-filters > form select', remoteFormSubmitHandler
$(document).on 'change', '.select-filters > form select', remoteFormSubmitHandler

$(document).on 'click', '.labelled-field :checkbox', ->
  $this = $(this)
  $this.closest('.labelled-field').toggleClass('checked', $this.prop('checked'))

$(document).on 'click', '#app-title-nav-search', ->
  $searchbar = $('#query-searchbar')
  if $searchbar.length
    $searchbar.fadeOut(200).fadeIn(200)
  else
    $searchbar = $('#app-searchbar')
    $searchbar.slideToggle()
  $searchbar.queue (next) ->
    $searchbar.find('input[type="text"]').select().focus()
    next()
  false

$(document).on 'change', '#event_country_id', ->
  clearSelect($('#event_time_zone'))
  val = $('#event_country_id').val()
  $.get '/events/time_zone_select?' + $.param({country_id: val}), null, null, 'script'

clearSelect = ($select) ->
  $select.val('')
  $select.children('option').slice(1).remove()

$(document).on 'keyup keypress', '.search-selection-table input[type="text"]', (e) ->
  return false if e.which == 13

$(document).on 'keyup', '.search-selection-table input[type="text"]', delay: 500, (e) ->
  $this = $(this)
  return if $this.queue().length
  $this.delay(e.data.delay) if e.data && e.data.delay
  $this.queue (next) ->
    $table = $this.closest('.search-selection-table').find('table')
    $persistedTrs = $table.find('tr[data-id]')
    $table.find('input:checkbox:not(:checked)').closest('tr').not($persistedTrs).remove()
    val = $this.val().trim()
    if val
      persistedIds = $persistedTrs.map(-> $(this).data('id')).get()
      $.get '/admin/insights/form_researchable_insights_table_fields?' + $.param({q: val, exclude: persistedIds, prefix: $this.data('prefix')}), null, ->
        next()
      , 'script'
    else
      next()
  false

$(document).on 'click', '.content-toggle', ->
  $($(this).data('target')).slideToggle 'fast', ->
    $this = $(this)
    $this.css('display', '') unless $this.is(':visible')

$(document).on 'click', '.js-vendormatch-consent-ok', ->
  setFlagCookie('vendormatch_consented', true)
  $('.js-vendormatch-consent').remove()

$(document).on 'change', 'select.section-toggle', ->
  $this = $(this)
  val = $this.val()
  $sections = $("[data-section-toggle='#{val}']")
  $sections.show()
  $("[data-section-toggle]").not($sections).hide()

$(document).on 'click', '.activitypage .detail-link', ->
  $(this).closest('li').children('.question-list').slideToggle()

$(document).on 'click', '.action-reload-page', ->
  $queryDict = {}
  location.search.substr(1).split("&").map((item)->
    $queryDict[item.split("=")[0]] = item.split("=")[1]
  )
  $params = new URLSearchParams(location.search);
  $x = $params.get('trackable_type');
  $params = '?trackable_type=' + $x;
  if $queryDict["vendor_view"] == "true"
    $params += "&vendor_view=true"
  
  window.history.pushState({}, document.title, window.location.pathname + $params);
  location.reload(true);

$(document).on 'click', '.action-reload-checks', ->
  $params = new URLSearchParams(location.search);
  $x = $params.get('type');
  $params = '?type=' + $x;
  window.history.pushState({}, document.title, window.location.pathname + $params);
  location.reload(true);

$(document).on 'click', '.todays-date', ->
  $fields = $(this).closest('.labelled-field').find('select')
  date = new Date();
  $fields.eq(0).val('' + date.getFullYear())
  $fields.eq(1).val('' + (date.getMonth() + 1))
  $fields.eq(2).val('' + date.getDate())

$(document).on 'click', '#account-popup-trigger', (event) ->
  event.preventDefault();
  $this = $(this)
  $('#account-popup').css('transition', 'none')
  $('#account-popup').css('opacity', 0)
  $('#account-popup').show()
  this.clientWidth
  $('#account-popup').css('top', $this.position().top + $this.outerHeight())
  $('#account-popup').css('transition', 'opacity 306ms cubic-bezier(0.4, 0, 0.2, 1) 0ms')
  $('#account-popup').css('opacity', 1)

# LOADER...

$('.loader').hide()
$(document).ajaxStart ->
  $('#app-content-inner').css('opacity', '.8');
  $('.loader').css('top', getCurrentHeight() / 2.7);
  $('.loader').show();
  $('.linput > *, button, a, input[type="submit"]').addClass('isDisabled');
  return
$(document).ajaxStop ->
  $('.loader').hide();
  $('#app-content-inner').css('opacity', '1');
  $('.linput > *, button, a, input[type="submit"]').removeClass('isDisabled');
  return

# /user/:id/dashboard/statpage
$('#dashboard-statpage .dashboard-view-all').on 'click', ->
  $h = getCurrentHeight()
  $('.loader').css('top', $h / 2 );
  $('.loader').show();
  $('#app-content-inner').css('opacity', '.7')

# /users/:id/dashboard/profile
$('#dashboard-account.block.main div.block-part ul.nav-list li a').on 'click', ->
  $h = getCurrentHeight()
  $('.loader').css('top', $h / 3 );
  $('.loader').show();
  $('#app-content-inner').css('opacity', '.7')


# dashboard aside
$('aside#dashboard-account.block.main div.block-part ul.nav-list a.dashboard-view-all').on 'click', ->
  $h = getCurrentHeight()
  $('.loader').css('top', $h / 3 );
  $('.loader').show();
  $('#app-content-inner').css('opacity', '.7')

# /admin/vendors/:id
$('#admin-company div.heading-group a').on 'click', ->
  $h = getCurrentHeight()
  $('.loader').css('top', ($h + ($h * 2) ));
  $('.loader').show();
  $('#app-content-inner').css('opacity', '.7')


# /admin/solutions
$('#admin-products div.link-bar a').on 'click', ->
  $h = getCurrentHeight()
  $('.loader').css('top', ($h / 3 ));
  $('.loader').show();
  $('#app-content-inner').css('opacity', '.7')


# individual vendor selection from name clmn on /admin/vendors
$('#admin-companies-table.filter-table-wrapper table tbody tr td a').on 'click', ->
  $h = getCurrentHeight()
  $('.loader').css('top', ($h / 3 ));
  $('.loader').show();
  $('#app-content-inner').css('opacity', '.7')

$('#admin-companies div.link-bar a').on 'click', ->
  $h = getCurrentHeight()
  $('.loader').css('top', ($h / 3 ));
  $('.loader').show();
  $('#app-content-inner').css('opacity', '.7')

$('.filter-table-wrapper nav.pagination span.next a').on 'click', ->
  $h = getCurrentHeight()
  $('.loader').css('top', ($h + 5500 ));
  $('.loader').show();
  $('#app-content-inner').css('opacity', '.7')

$('html.root.js.no-responsive body div#app-base.app-admin main#app-content div#app-content-inner.holder div#admin-tracks div.link-bar a').on 'click', ->
  $h = getCurrentHeight()
  $('.loader').css('top', ($h / 3 ));
  $('.loader').show();
  $('#app-content-inner').css('opacity', '.7')

getCurrentHeight = () ->
  $h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  return $h

# END LOADER

# PROJECT POPUP
$(document).on 'click', '#project-links-enhanced .project-controls', (event) ->
  $popup = $(this).next('.popup')
  $popup.show()

$(document).on 'click', '#project-links-enhanced .close', (event) ->
  $(this).closest('.popup').hide()
  false


$(document).on 'mouseover', '#vendormatch-hover', (event) ->
  $('.hide-vendormatch').show()
  $(this).next().show()
