$(document).ready ->
  hideComparisonTopics()

$(document).on 'click', '#project-comparison a.topic-toggle', ->
  $topic = $(this).closest('.topic')
  $toggle = $topic.find('.topic-toggle')
  if $topic.hasClass('topic-hidden')
    $topic.removeClass('topic-hidden')
    $toggle.text($toggle.data('text-hide'))
    $topic.find('.questions-table').slideDown()
  else
    $topic.addClass('topic-hidden')
    $toggle.text($toggle.data('text-show'))
    $topic.find('.questions-table').slideUp()

$(document).on 'click', '#project-download', (e) ->
  hiddenTopics = ($('#project-comparison .topic-hidden').map -> $(this).data('id')).get()
  if hiddenTopics.length
    e.preventDefault();
    location.href = $(this).attr('href') + "?hidden_topics=#{hiddenTopics.join()}"

$(document).on 'change', '#project_client_type', ->
  $this = $(this)
  $fieldset = $('#project-form-client')
  if $this.val() == 'client' then $fieldset.slideDown() else $fieldset.slideUp()

$(document).on 'change', '#project .project-success-reason-select', ->
  $this = $(this)
  $option = $this.children('option:selected')
  $this.next('small').text($option.data('description'))

$(document).on 'mouseover', '.app-restricted-hover > .link', (event) -> 
  $('.hide-on-outside-click').hide()
  $(this).next().show()


$(document).on 'mouseover', '.permissions-hover', (event) ->
  $('.hide-on-outside-click').hide()
  solution_btn = $(this).children('.link')[0]
  if solution_btn == undefined
    $popup = $('#restricted-view')
    $popup.show();
  else
    $(this).children('.link').next().show()

$(document).on 'click', '#restricted-view .close', (event) ->
  $popup = $(this).parent().parent()
  $popup.hide();


$(document).on 'mouseover', '.permissions-hover-solution-profile', (event) ->
  $('.hide-on-outside-click').hide()
  solution_btn = $(this).children('.link')[0]
  if solution_btn == undefined
    $popup = $('#restricted-view-solution-profile')
    $popup.show();
  else
    $(this).children('.link').next().show()

$(document).on 'click', '#restricted-view-solution-profile .close', (event) ->
  $popup = $(this).parent().parent()
  $popup.hide();


$(document).on 'mouseover', '.filter-restricted-hover', (event) -> 
  $('.hide-on-outside-click').hide()
  $(this).next().show()

$(document).on 'click', '.celent-relative .close', (event) ->
  $popup = $(this).parent().parent()
  $popup.hide();

$(document).on "click", ".filter-comparison-question", (e) ->
  $("#q").val(e.target.innerHTML)
  $question_id = $(this).data("id")
  $project_id = $("#filter-comparison-list").data("id")
  $.ajax({
    type: "PATCH",
    url: "/vendormatch/projects/" + $project_id + "/comparison",
    dataType:"script",
    data : {'questionId': $question_id}
    format:"js",
    success: (data) ->
      $('#filter-comparison-dropdown').addClass("filter-hidden") 
      hideComparisonTopics()
  })

$(document).on "click", (e) ->
  $filter_comp = document.querySelector("#filter-comparison-dropdown")
  if $filter_comp != null && (e.target.id != "q" && e.target.className != "filter-comparison-question") 
    $("#filter-comparison-dropdown").addClass("filter-hidden")
  if $filter_comp != null && e.target.id == "q" && $filter_comp.classList.value == 'filter-hidden'
    $('#filter-comparison-dropdown').removeClass("filter-hidden")  
      
    

$(document).on 'input', '#q', ->
  $projectId = $(this).data("projectId")
  if $(this).is(':focus')
    $.ajax({
      type : "GET",
      url : "/vendormatch/projects/" + $projectId + "/get_filtered_solution_types?q=" + this.value
      dataType: 'script',
      format: 'js',
      success: (data) ->
        $('#filter-comparison-dropdown').removeClass("filter-hidden") 
    })
    if this.value.length == 0
      $.ajax({
        type: "PATCH",
        url:"/vendormatch/projects/" + $projectId + "/comparison",
        dataType:"script",
        data: {'questionId': this.value},
        format: "js",
        success: (data) ->
          $('#filter-comparison-dropdown').addClass("filter-hidden")
          hideComparisonTopics() 
      })

$(document).on 'focusin', '#q', ->
  $('#filter-comparison-dropdown').removeClass("filter-hidden")


hideComparisonTopics = ->
  hiddenTopics = ($('#project-comparison .topic-hidden'))
  if hiddenTopics.length
    hiddenTopics.each -> 
      $toggle = $(this).find('.topic-toggle')
      $toggle.text($toggle.data('text-show'))
      $(this).find('.questions-table').slideUp()


