$(document).ready ->
  $('#admin-bulletin-logo-input').cropSource $('#admin-bulletin-logo'), 250, 150
  updateFeaturedDisplay $('#admin-bulletin-bulletin_analysts .labelled-field:first-child').find('input:checkbox:checked')

$(document).on 'click', '#admin-bulletin-bulletin_analysts .labelled-field:first-child input:checkbox', ->
  updateFeaturedDisplay($(this))

updateFeaturedDisplay = ($checks) ->
  return unless $checks.length
  lfs = $checks.closest('li').children('.labelled-field ~ .labelled-field')
  if $checks.prop('checked') then lfs.css('display', 'inline-block') else lfs.hide()
