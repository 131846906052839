$(document).ready ->
  $('#admin-insight-logo-input').cropSource $('#admin-insight-logo'), 250, 150
  $('#admin-insight-tile_image-input').cropSource $('#admin-insight-tile_image'), 400, 400, false
  updateFeaturedDisplay $('#admin-insight-insight_analysts .labelled-field:first-child').find('input:checkbox:checked')

$(document).on 'click', '#admin-insight-insight_analysts .labelled-field:first-child input:checkbox', ->
  updateFeaturedDisplay($(this))

$(document).on 'click', '#admin-insight-report-original_target_date .before-edit button', ->
  $root = $(this).closest('#admin-insight-report-original_target_date')
  $root.find('.before-edit').hide()
  $root.find('.after-edit').show()

updateFeaturedDisplay = ($checks) ->
  return unless $checks.length
  lfs = $checks.closest('li').children('.insight_analyst-detail')
  if $checks.prop('checked') then lfs.css('display', 'inline-block') else lfs.hide()

$('.tou-verification-container input').change(->
  $input = $(this)
  $targetObj = $('ul.media_pieces.media_documents.tou-check');
  if $input.is(':checked')
    $targetObj.removeClass('isDisabled');
  else
    $targetObj.addClass('isDisabled');
  return
).change()
