$(document).ready ->
  companySetupCrop()
  companySearchCheck() if $('#company-search-check-form').length

companySetupCrop = ->
  $('#company-logo-input').cropSource $('#company-logo'), 250, 150

companySearchCheck = ->
  q = $.trim $('#company-name').val()
  if q
    f = $('#company-search-check-form')
    f.find('.q').val(q)
    f.serialize()
    $.post f.attr('action'), f.serialize(), null, 'script'
  else
    $('#company-search-check').empty()

$(document).on 'keyup', '#company-name', ->
  form = $('#company-search-check-form')
  return if form.length == 0 or form.queue().length
  form.delay(1000)
  form.queue (next) ->
    companySearchCheck()
    next()
