

$(document).ready ->
  onPageLoad()

onPageLoad = ->
  $all = $('#search-all')
  if window.location.hash == "#section=146"
    data_mps_approved = $all.attr "data-mps"
    $realmName = $('#search-topbar')[0].dataset.realm
    if data_mps_approved == 'false' && $realmName == "Marsh"
      url = "#{window.location.protocol}//#{window.location.host}/mps-services?return_to=/vendormatch/search&section_id=146"
      window.location.href = url  
    else 
      hideMyProjectBtn()
  else if window.location.search != ''
    $query_list = window.location.search.replace("?", "").split("&")
    if $query_list.includes('section=146')
      hideMyProjectBtn()
  return unless $all.length
  history.scrollRestoration = 'manual' if 'scrollRestoration' of history
  # add quick lists for topics
  $('.search-topic').each (i, search_topic) ->
    $search_topic = $(search_topic)
    searchCreateQuickList($search_topic)
    searchBuildQuickListItems($search_topic)
  $topbarQuick = $('#search-topbar .search-topic-quick')
  $('#search-topbar-selected-terms').children('span').each (i, st) ->
    $st = $(st)
    searchBuildQuickListItem($topbarQuick, $st.data('id'), $st.data('name') or $st.text(), $st.data('ord'), true)
  # add any auto-selected inputs in a new search form to data to be processed on first update
  inputs = $('form.new-search-form').find('input:checked')
  $searchText = $('form.new-search-form #search_text')
  inputs = inputs.add($searchText) if $searchText.prop('value')
  if inputs.length
    inputs = inputs.add(inputs.siblings(':input:hidden'))
    $('form.new-search-form').data('paramSources', inputs)
  # setup carousel (if it isn't already)
  $('#search-adverts .adverts-carousel').each (i, carousel) ->
    searchSetupCarousel($(carousel))
  if $('#search-topbar').length
    # update results-min area
    searchUpdateResultsMin() if $all.hasClass('has_topbar_terms')
    # update for url hash
    hash = window.location.hash
    $('#search-all').data('hashStack', [hash or searchDefaultHash()])
    if hash and hash != searchDefaultHash()
      searchUpdateView(hash, false)
    else if $all.hasClass('topbar_shown')
      searchToggleSections(false, !$('#search-topbar-suggestions').length)

searchDefaultHash = ->
  if $('#search-all').hasClass('topbar_shown') then '#sections' else '#results'

showMyProjectBtn = -> 
  $("#search-controls").find('[data-popup="my-projects-popup"]').each (i, a) ->
    if ($(a).css('display') == 'none')
      $(a).css('display', 'inline-block') 


hideMyProjectBtn = ->
  $("#search-controls").find('[data-popup="my-projects-popup"]').each (i, a) ->
    $(a).css('display', 'none') 


searchSetupCarousel = ($carousel) ->
  if $carousel.css('position') == 'relative'
    $carousel.find('img').each (i, img) ->
      $img = $(img)
      $img.attr('src', $img.attr('src'))
  else
    $carousel.jCarouselLite(speed: 0, visible: 1, swipe: false, afterEnd: (li) ->
      $li = $(li)
      $img = $li.find('img')
      $img.attr('src', $img.attr('src'))
      setTimeout (-> $carousel.trigger('go')), if $li.data('loop_length') then parseInt($li.data('loop_length')) else 15000
    )
    $li = $carousel.children('ul').children('li.first')
    setTimeout (-> $carousel.trigger('go')), if $li.data('loop_length') then parseInt($li.data('loop_length')) else 15000

searchSwitchView = (hash_or_key, allowBack = false) ->
  if hash_or_key == 'best_sections'
    $sections = $('#search-topbar-main').find('input:checked').closest('.section')
    if $sections.length == 1
      hash = "#section=#{$sections.data('id')}"
    else
      hash = '#section=all'
  else
    hash = hash_or_key
  if 'onhashchange' of window
    hashStack = $('#search-all').data('hashStack')
    if allowBack and hashStack[hashStack.length - 2] == hash
      hashStack.pop()
      hashStack.pop()
      history.back()
    else
      window.location.hash = hash
  else
    searchUpdateView(hash)

$(window).on 'popstate', ->
  if window.location.hash == "#section=146" 
    searchToggleMain(false, false)
    $sections = $('#search-topbar-main').children(".section")
    if $sections.length
      if $sections.is(':visible')
      else
        onPageLoad()
        $('#search-results-min').css('display', 'none')
    for i in $("#mps-search-section > li")
      i.children[0].checked = false
    test = $(".search-topic-quick")
    test.empty()
  else
    if window.location.pathname == '/vendormatch/search' && window.location.hash == '' && window.location.search == ''
      window.location.replace(window.location)
    
      

$(window).on 'hashchange', ->
  $all = $('#search-all');
  return unless $all.length
  hash = window.location.hash
  $all.data('hashStack').push(hash || searchDefaultHash())
  searchUpdateView(hash)

searchUpdateView = (hash, animate = true) ->
  $all = $('#search-all')
  if hash == '#results'
    searchToggleMain(true, animate)
    $search_queries = window.location.search
    if $search_queries != ''
      $split_queries = $search_queries.split("&")
    $all.data('hash', hash)
  else
    $all = $('#search-all')
    ss = hash.split('#section=')[1]
    if ss == 'all'
      $sections = $('#search-topbar-main').children('.section').not('.partner-hide')
    else
      id = parseInt(ss, 10)
      $sections = $('#search-topbar-main').children(".section[data-id='#{id}']") if id
      $sections = null unless $sections and $sections.length
      hash = '#sections' unless $sections
    if $all.hasClass('topbar_shown')
      searchToggleSections($sections, animate)
    else
      searchToggleSections($sections, false)
      searchToggleMain(false, animate)
    $all.data('hash', hash)

searchToggleMain = (showMain, animate = true) ->
  $all = $('#search-all')
  return if $all.hasClass('topbar_shown') == !showMain
  if animate
    if showMain
      $('#search-topbar .search-topic-min').css('display', 'none')
      searchSlideViewElement $('#search-topbar .search-topic-content'), false, ->
        searchSlideViewElement $('#search-topbar .search-topic-min'), true
        $('#search-topbar .partner-hide').removeClass('partner-hide') # show parents hidden for partner
      searchSlideViewElement $('#search-sidebar'), true
      searchSlideViewElement $('#search-results'), true
      searchSlideViewElement $('#search-results-min'), false
    else
      $('#search-topbar .search-topic-content').css('display', 'none')
      $('#search-results-min').css('display', 'none')
      $('#search-sidebar').css('display', 'block')
      $('#search-results').css('display', 'block')
      searchSlideViewElement $('#search-topbar .search-topic-min'), false, ->
        searchSlideViewElement $('#search-topbar .search-topic-content'), true, ->
          searchSlideViewElement $('#search-results-min'), true if $all.hasClass('has_topbar_terms')
        searchSlideViewElement $('#search-sidebar'), false, -> $('#search-sidebar-inner').removeClass('toggleShown')
        searchSlideViewElement $('#search-results'), false
    $('#search-all').toggleClass('topbar_shown', !showMain)
    $('html, body').animate(scrollTop: 0);
  else
    $('#search-all').toggleClass('topbar_shown', !showMain)

searchToggleSections = ($sections, animate = true) ->
  $tilebox = searchSetupTilebox()
  duration = if animate then null else 0
  if animate
    $('html, body').animate(scrollTop: Math.min($(window).scrollTop(), $('#search-topbar-main').offset().top));
  if $sections and $sections.length
    if $tilebox.is(':visible')
      $tilebox.fadeOut duration, ->
        $sections.fadeIn duration
        $('#search-topbar-tiles-link').fadeIn duration
    else
      $tilebox.hide()
      $sections.fadeIn duration
      $('#search-topbar-tiles-link').fadeIn duration
  else
    $sections = $('#search-topbar-main').children(".section")
    if $sections.is(':visible')
      $sections.filter(':visible').fadeOut duration, -> $tilebox.fadeIn duration
      $('#search-topbar-tiles-link').fadeOut duration
    else
      $sections.hide()
      $tilebox.fadeIn duration
      $('#search-topbar-tiles-link').hide()

searchShowPopup = (search_topic, toggle = false) ->
  popup = search_topic.children('.search-topic-main')
  if popup.is(':visible')
    popup.fadeOut('fast') if toggle
    return
  $content = popup.children('.search-topic-content')
  $('.search-topic-main').not(popup).filter(':visible').fadeOut('fast')
  # adjust veritcal position of popups from 'search-filters
  if popup.closest('#search-filters').length
    # add popup to the dom temporarily so we can do some co-ord calculations
    popup.css(visibility: 'hidden', display: 'block')
    $scrolls = $content.children('.search-filters-base, .search-filters-parents, .search-filters-children')
    $scrolls.css('max-height': $(window).height() * 0.7)
    # the offset required to center the popup vertically in the viewport
    scrollTop = $(window).scrollTop()
    centerY = scrollTop + ($(window).height() - popup.outerHeight()) / 2
    # clamp offset so that the popup is still positioned next to it's related section
    max = popup.closest('.search-topic').offset().top - 8
    min = max + popup.closest('.search-topic').height() - popup.outerHeight()
    min = scrollTop if min < scrollTop
    centerY = if centerY < min then min else if centerY > max then max else centerY
    popup.offset({top: centerY})
    # horizontally align popup with results area
    popup.offset({left: $('#search-results').offset()['left']})
  popup.css(display: 'none', visibility: 'visible').fadeIn('fast')

searchCreateQuickList = (search_topic) ->
  if search_topic.find('.search-topic-quick').length == 0
    $main = search_topic.children('.search-topic-main')
    $main.before($('<ul />', class: 'search-topic-quick'))
    if $main.data('show-all')
      $main.before($('<a />', href: 'javascript:;', class: 'search-topic-more t-overline').text($main.data('show-all')))

searchBuildQuickListItems = (search_topic) ->
  $quick = search_topic.find('.search-topic-quick')
  $topicOrMain = search_topic.children('.search-topic-main')
  $topicOrMain = search_topic unless $topicOrMain.length
  $content = $topicOrMain.children('.search-topic-content')
  $quick.empty()
  $content.find("li[data-filter_suggested='1']").add($content.find('input:checked').parent()).each (i, li) ->
    $li = $(li)
    searchBuildQuickListItem($quick, $li.data('id'), $li.data('name') or $li.children('label').text(), $li.data('ord'), $li.children('input:checked').length > 0)

searchBuildQuickListItem = (quick, id, name, ord, checked) ->
  $quickLi = quick.children("li[data-id='#{id}']")
  if $quickLi.length == 0
    $quickLi = $('<li />', 'data-id': id, 'data-ord': ord).append($('<a />', href: 'javascript:;').append($('<span />').text(name)))
    searchAddInOrder(quick, $quickLi)
  if checked
    $quickLi.addClass('selected')
  else
    $quickLi.removeClass('selected')

searchAddInOrder = (container, newElement) ->
  newPrimary = parseInt(newElement.data('primary'), 10) || 0
  newOrd = parseInt(newElement.data('ord'), 10)
  container.children().each (i, element) ->
    $element = $(element)
    primary = parseInt($element.data('primary'), 10) || 0
    ord = parseInt($element.data('ord'), 10)
    if newPrimary > primary || newPrimary == primary && newOrd < ord
      $element.before(newElement)
      false
  container.append(newElement) if newElement.parent().length == 0

searchSetupTilebox = ->
  $tilebox = $('#search-topbar-tilebox')
  loadHeaderText()

  return $tilebox if $tilebox.length
  $topbarMain = $('#search-topbar-main')
  $tilebox = $('<div />', id: 'search-topbar-tilebox').hide().appendTo($topbarMain)
  $sections = $topbarMain.children('.section')
  $sections.each (i, section) ->
    $section = $(section)
    $tile = $('<div />', class: 'tile', 'data-id': $section.data('id'), 'data-mps': $section.data('mps'), 'data-mps-acknowledged':$section.data('mps-acknowledged'))
    $tile.append($('<span />', class: 'header t-subtitle1').text($section.children('h2').text()))
    $tile.append($('<span />', class: 'detail').text($section.children('.detail').text()))
    $tile.addClass('partner-hide') if $section.hasClass('partner-hide')
    $tilebox.append($tile)
  $realmName = $('#search-topbar')[0].dataset.realm
  
  if $realmName != 'Marsh'
    $tilebox.append($('<div />', class: 'full-tile').append($('<a />', href: 'javascript:;', id: 'search-topbar-tilebox-all').text('view all')))
  $tilesLink = $('<a />', href: 'javascript:;', id: 'search-topbar-tiles-link').append($('<img>', src: '/assets/tiles-1035ceae420559c8665fad4a727f68411f3822f5dcfda37c810dbe25a9e90f43.png')).append('back to section view').hide()
  $topbarMain.children('.subheader').after($tilesLink)
  $tilebox

loadHeaderText = ->
    $topbar = $('#search-topbar')[0]
    if $topbar != undefined
      $realmName = $('#search-topbar')[0].dataset.realm
      $search_queries = window.location.search.split("&")
      if $realmName == 'Marsh' && $search_queries != undefined && $search_queries.length > 1
        $section = $search_queries[1].split("=")
        if $section[0] == 'section' && $section[1] == '146'
          $('#app-heading-text > h1').html("MARSH CYBER MARKETPLACE SERVICES")
          $("#search-topbar > .search-topic > .search-topic-min > .main").hide()
          $("#search-topbar > .search-topic > .search-topic-content > .main").hide()
          $("#search-topbar > .search-topic > .search-topic-min > .incident").hide()
          $("#search-topbar > .search-topic > .search-topic-content > .incident").hide()
          $("#search-topbar > .search-topic > .search-topic-min > .market-place").show()
          $("#search-topbar > .search-topic > .search-topic-content > .market-place").show()
          $('#search-sidebar-inner').hide()
        else if $section[0] == 'section' && $section[1] != '146'
          $('#app-heading-text > h1').html("Marsh Cyber Network Directory")
          $("#search-topbar > .search-topic > .search-topic-min > .main").hide()
          $("#search-topbar > .search-topic > .search-topic-content > .main").hide()
          $("#search-topbar > .search-topic > .search-topic-min > .incident").show()
          $("#search-topbar > .search-topic > .search-topic-content > .incident").show()
          $("#search-topbar > .search-topic > .search-topic-min > .market-place").hide()
          $("#search-topbar > .search-topic > .search-topic-content > .market-place").hide()
      else if $realmName == 'Marsh'
        if window.location.hash == ''
          $('#app-heading-text > h1').html("Marsh Cyber Network Directory")
          $("#search-topbar > .search-topic > .search-topic-min > .main").show()
          $("#search-topbar > .search-topic > .search-topic-content > .main").show()
          $("#search-topbar > .search-topic > .search-topic-min > .incident").hide()
          $("#search-topbar > .search-topic > .search-topic-content > .incident").hide()
          $("#search-topbar > .search-topic > .search-topic-min > .market-place").hide()
          $("#search-topbar > .search-topic > .search-topic-content > .market-place").hide()
        else
          $hash = window.location.hash.split("=")
          if $hash[0] == '#section' && $hash[1] != 'all'
            if $hash[1] == '146' 
              $('#app-heading-text > h1').html("MARSH CYBER MARKETPLACE SERVICES")
              $("#search-topbar > .search-topic > .search-topic-min > .main").hide()
              $("#search-topbar > .search-topic > .search-topic-content > .main").hide()
              $("#search-topbar > .search-topic > .search-topic-min > .incident").hide()
              $("#search-topbar > .search-topic > .search-topic-content > .incident").hide()
              $("#search-topbar > .search-topic > .search-topic-min > .market-place").show()
              $("#search-topbar > .search-topic > .search-topic-content > .market-place").show()
            else
              $('#app-heading-text > h1').html("Marsh Cyber Network Directory")
              $("#search-topbar > .search-topic > .search-topic-min > .main").hide()
              $("#search-topbar > .search-topic > .search-topic-content > .main").hide()
              $("#search-topbar > .search-topic > .search-topic-min > .incident").show()
              $("#search-topbar > .search-topic > .search-topic-content > .incident").show()
              $("#search-topbar > .search-topic > .search-topic-min > .market-place").hide()
              $("#search-topbar > .search-topic > .search-topic-content > .market-place").hide()

$(document).ready ->
  loadHeaderText()

$(document).on 'click', '#search-topbar-tilebox .tile', (event) ->
  if $(this).data('mps') == true && $(this).data('mps-acknowledged') == false
    window.location.href = "#{window.location.protocol}//#{window.location.host}/mps-services?return_to=#{window.location.pathname}&section_id=#{$(this).data('id')}"   
  else  
    searchSwitchView("#section=#{$(this).data('id')}")
  false
$(document).on 'click', "#mps-acknowledge", (event) ->
  returnParams = new URLSearchParams(window.location.search).get('return_to')
  url = "#{window.location.protocol}//#{window.location.host}#{returnParams}"
  window.location.href = url

$(document).on 'click', '#search-topbar-tilebox-all', (event) ->
  searchSwitchView('#section=all')
  false

$(document).on 'click', '#search-topbar-tiles-link', (event) ->
  searchSwitchView('#sections', true)
  showMyProjectBtn()
  $inputs = $('#search-topbar-main').find('input:checked')
  if $inputs.length
    $inputs.prop('checked', false)
    searchProcessPost($inputs)
  false

$(document).on 'click', '#search-sidebar-toggle', (event) ->
  $inner = $('#search-sidebar-inner')
  $inner.slideToggle 'fast', ->
    $inner.toggleClass('toggleShown', $inner.is(':visible'))
    $inner.css('display', '')
  false

$(document).on 'click', '.search-topic-quick li a', (event) ->
  $this = $(this)
  $li = $this.closest('li')
  topbar = !!$li.closest('#search-topbar').length
  searchCheckboxFor($li.closest('.search-topic'), $li.data('id')).first().trigger('click')
  if topbar and !$('#search-all').hasClass('has_topbar_terms')
    searchSwitchView('#sections')
  false

searchCheckboxFor = (topic, id) ->
  $topicOrMain = topic.children('.search-topic-main')
  $topicOrMain = topic unless $topicOrMain.length
  $checkbox = $topicOrMain.children('.search-topic-content')
  .find("li[data-id='#{id}']").children(':checkbox')

searchProcessPost = (inputs) ->
  # update quick lists
  inputs.filter(':checkbox').each (i, input) ->
    $input = $(input)
    $li = $input.parent('li')
    id = $li.data('id')
    $quick = $li.closest('.search-topic').find('.search-topic-quick')
    $quickLi = $quick.children("li[data-id='#{id}']")
    if $input.prop('checked')
      searchBuildQuickListItem($quick, $li.data('id'), $li.data('name') or $li.children('label').text(), $li.data('ord'), $li.children('input:checked').length > 0)
    else if $li.data('filter_suggested')
      $quickLi.removeClass('selected')
    else
      $quickLi.remove()
  # do form submission
  form = $('.search-form')
  # get the hidden inputs as well as the check boxes
  new_ps = inputs.add(inputs.siblings(':input:hidden'))
  # we store inputs that need to be updated as data of the form. Add new inputs to any existing data
  ps = form.data('paramSources')
  form.data('paramSources', if ps then ps.add(new_ps) else new_ps)
  # queue the post function. The post may be skipped if all inputs have been processed by a previous queue entry
  form.queue ->
    $form = $(this)
    # grab the inputs to be processed
    paramSources = $form.data('paramSources')
    # check that there are params to process
    if paramSources
      # clear the refreshed class from controls to get them reloaded on next use
      $('#search-controls').children('.popup').removeClass('refreshed')
      # update views as necessary for searches with a topbar
      searchUpdateForTopbar() if $('#search-topbar').length
      # clear the input data so it isn't processed again
      $form.data('paramSources', null)
      # get the form-level inputs required for the post
      form_params = $form.children('input')
      $.post($form.attr('action') + '.json', paramSources.add(form_params).serialize(), (data) ->
        # handle redirect responses
        if data.redirect
          window.location.href = data.redirect
          # clear out the queue to prevent additional search creations happening in the background
          $form.clearQueue()
          return
        # check if it's a newly created search. If so
        # we need to make some form modifications for future posts
        old_id_match = $form.attr('action').match(/\d+$/)
        old_id = parseInt(old_id_match[0], 10) if old_id_match
        unless old_id == data.id
          # make the new form action path using the returned search ID
          newPath = $form.attr('action')
          newPath = newPath.substring(0, newPath.length - old_id.toString().length - 1) if old_id
          newPath = newPath + '/' + data.id
          # make the new location by adding the search_id as a param
          newLocation = data.location
          $all = $('#search-all')
          hash = $all.data('hash')
          newLocation += hash if hash
          # if the browser support history.replaceState then use that, otherwise we just do a standard redirect and return
          if window.history.replaceState
            $form.attr('action', newPath)
            window.history.replaceState(null, '', newLocation)
            $all.data('hashStack', [hash])
            unless $form.children('input[name="_method"][value="patch"]').length
              $form.append $('<input>').attr({type: 'hidden', name: '_method', value: 'patch'})
            searchFixReturnTos()
          else
            window.location.replace(newLocation)
            # clear out the queue to prevent additional search creations happening in the background
            $form.clearQueue()
            return
          # remove the creation-specific parameters from the form
          $form.children('input.create_field').remove()
        # remove the notices section if there is one
        $('#app-notices').remove()
        # update the various page sections using the returned data
        $('#search-results').html($(data.results_html))
        searchUpdateResultsMin()
        $('#search-bookmarked').prop('checked', !!data.bookmarked)
        searchUpdateBookmark()
        # TODO use merge rather than nested loop
        for row in data.filters_data
          existing = $('#search-topic-' + row.id)
          if row.html
            if existing.length
              $content = existing.children('.search-topic-main').children('.search-topic-content')
              $n = $(row.html)
              $nContent = $n.children('.search-topic-main').children('.search-topic-content')
              $content.html($nContent.html())
              searchBuildQuickListItems(existing)
            else
              $search_topic = $(row.html)
              $content = $search_topic.children('.search-topic-main').children('.search-topic-content')
              searchAddInOrder($('#search-filters'), $search_topic)
              searchCreateQuickList($search_topic)
              searchBuildQuickListItems($search_topic)
          else
            existing.remove()

        $adverts = $('#search-adverts')
        $unmatchedSpaces = $adverts.children('.adverts-space')
        $newSpaces = $(data.adverts_html).filter('.adverts-space')
        $newSpaces.each (i, newSpace) ->
          $newSpace = $(newSpace)
          $oldSpace = $adverts.children("[data-space_id=#{$newSpace.data('space_id')}]")
          if $oldSpace.length
            $unmatchedSpaces = $unmatchedSpaces.not($oldSpace)
            return true if $newSpace.data('rule_id') == $oldSpace.data('rule_id')
            $oldSpace.find('.adverts-carousel').trigger('endCarousel')
            $oldSpace.replaceWith($newSpace)
          else
            searchAddInOrder($adverts, $newSpace)
          $newSpace.find('.adverts-carousel').each (i, carousel) ->
            searchSetupCarousel($(carousel))
        $unmatchedSpaces.remove()
      , 'json').always ->
        # dequeue after all processing has finished
        $form.dequeue()
    else
      # dequeue immediately if we're skipping the post
      $form.dequeue()

searchCheckLoadNextPage = ->
  $results = $('#search-results')
  return unless $results.length and $results.is(":visible")
  triggerDistance = 50
  if $results.offset().top + $results.height() - $(window).scrollTop() - $(window).height() < triggerDistance
    searchLoadNextPage()

searchLoadNextPage = ->
  $more = $('#search-results-list')
  return if $more.length == 0 or $more.data('next_page_blocked')
  $more.data('next_page_blocked', true)
  nextPage = ($more.data('current_page') or 1) + 1
  $more.data('current_page', nextPage)
  params = XUtils.paramsHash(window.location.search)
  params['page'] = nextPage
  $.get '?' + $.param(params), (data) ->
    $('#search-results').children('form').children('ul').append($(data.results_items_html))
    $more.data('next_page_blocked', false) unless data.last_page
  , 'json'
  false

searchSelectIds = (ids...) ->
  $inputs = $()
  $.each ids, (i, id) ->
    return true unless id
    $check = $("#search-all").find("input[name='search[search_terms_attributes][#{id}][option]']")
    if $check.length
      $check.prop('checked', true)
      $inputs = $inputs.add($check)
    else
      $inputs = $inputs.add($('<input />', name: "search[search_terms_attributes][#{id}][option]", value: "Yes"))
      $inputs = $inputs.add($('<input />', name: "search[search_terms_attributes][#{id}][question_id]", value: id))
  searchProcessPost(searchAddAffected($inputs))

searchFixReturnTos = ->
  return_to = encodeURIComponent(window.location.pathname + window.location.search + window.location.hash)
  $('a.return_to').each (i, a) ->
    $a = $(a)
    $a.prop('href', $a.prop('href').replace(/(.*)([?&]return_to=).*?(&.*|$)/, "$1$2#{return_to}$3"))

searchClearSuggestions = ->
  $('#search-field').val('')
  $main = $('#search-topbar-main')
  $main.queue (next) ->
    $('#search-topbar-suggestions').fadeOut('fast')
    next()
  $main.queue (next) ->
    $('#search-topbar-suggestions').remove()
    $main.fadeIn('fast')
    next()

$(document).on 'input propertychange keyup keypress', '#search_text', (e) ->
  return false if e.which == 13
  $this = $(this)
  w = if $.trim($this.val()).length then 'show' else 'hide'
  $('#search-text_extra').animate({width: w});
  timestamp = new Date().getTime()
  $this.queue (next) ->
    diff = new Date().getTime() - timestamp
    setTimeout next, 500 + timestamp - new Date().getTime()
  $this.queue (next) ->
    searchProcessPost($this) if $this.queue().length == 1
    next()

$(document).on 'click', '#search_text_extra', ->
  searchProcessPost($(this))

$(document).on 'input propertychange keyup keypress', '#search-field', ->
  $this = $(this)
  timestamp = new Date().getTime()
  $this.queue (next) ->
    diff = new Date().getTime() - timestamp
    setTimeout next, 500 + timestamp - new Date().getTime()
  $this.queue (next) ->
    if $this.queue().length == 1
      params = {q: $.trim $('#search-field').val()}
      areaId = $('#search_area_id').attr('value')
      params['area_id'] = areaId if areaId
      $.get $('.search-form').attr('action') + '/suggestions?' + $.param(params), null, null, 'script'
    next()

$(document).on 'keyup keypress', '#search-field', (event) ->
  if event.which == 13
    $('#search-field').blur();
    false
  else
    true

$(document).on 'click', '#search_only_subscription', ->
  searchProcessPost($(this))

$(document).on 'click', '#search_source', ->
  searchProcessPost($(this))

$(document).on 'click', '#search-topbar-suggestions-clear a', (event) ->
  searchClearSuggestions()

$(document).on 'click', '#search-topbar-suggestions .question-group a', (event) ->
  $this = $(this)
  id = $this.data('id')
  requiredId = $this.data('required_question_id')
  searchSelectIds(id, requiredId)
  searchClearSuggestions()
  $main.queue (next) ->
    searchSwitchView('#results')
    next()
  false

unless 'ontouchstart' of window
  $(document).on 'mouseover mouseout', '#search-topbar-suggestions .detail-links a', (event) ->
    $(this).closest('.detail-links').siblings('a').toggleClass('hover', event.type == 'mouseover')

$(document).on 'click', '.search-topic-content .close', (event) ->
  searchShowPopup($(this).closest('.search-topic'), true)
  false

$(document).on 'click', '.search-topic-content :checkbox', (event) ->
  searchProcessPost(searchAddAffected($(this)))

$(document).on 'change', 'select', (event) ->
  $this = $(this)
  searchProcessPost($this.closest('.search-topic').find('input').add($this))
  false

searchAddAffected = ($inputs) ->
  $checks = $()
  $unchecks = $()
  $inputs.each (i, input) ->
    $input = $(input)
    $li = $input.closest('li')
    $content = $li.closest('.search-topic-content')
    $sameInputs = $content.find("input[name='#{$input.prop('name')}']").not($input)
    if $input.prop('checked')
      $checks = $checks.add($sameInputs)
    else
      $unchecks = $unchecks.add($sameInputs)
    if $li.hasClass('search-filter-parent') and !$input.prop('checked')
      parentId = $li.data('parent-id')
      $unchecks = $unchecks.add($content.children('.search-filters-children, .search-filters-children-selected').filter((i, d) -> d.getAttribute('data-parent') == parentId.toString()).find('input:checked'))
    $section = $input.closest('.section')
    if $section.length and $input.prop('checked')
      $sectionChecks = $section.find('input:checked')
      $section.siblings('.section').find('input:checked').each (i, c) ->
        $c = $(c)
        $unchecks = $unchecks.add($c) unless $sectionChecks.filter("[name='#{$c.prop('name')}']").length
  $checks.prop('checked', true)
  $unchecks.prop('checked', false)
  $inputs.add($checks).add($unchecks)


unless 'ontouchstart' of window
  hoverTimer = null
  $(document).on 'mouseenter mouseleave', '#search-topbar .section li label', (event) ->
    $this = $(this)
    $li = $this.closest('li')
    if hoverTimer
      clearTimeout(hoverTimer)
      hoverTimer = null
    if event.type == 'mouseenter'
      hoverTimer = setTimeout ->
        $detail = $li.children('.question-detail')
        if $detail.length
          $detail.show() if $detail.text()
        else
          $.get '/questions/' + $li.data('id'), (data) ->
            if $li.children('.question-detail').length == 0
              $detail = $('<div />', class: 'question-detail')
              $detail.text(data.search_detail) if data.search_detail
              $detail.hide()
              $li.append($detail)
              $detail.show() if $detail.text() and $this.is(':hover')
          , 'json'
      , 500
    else
      $li.children('.question-detail').hide()

$(document).on 'click', '.search-filters-parents label', (event) ->
  $li = $(this).closest('li')
  if $li.find('input').prop('checked') and !$li.hasClass('selected')
    $content = $li.closest('.search-topic-content')
    parentId = $li.data('parent-id')
    childDiv = $content.children('.search-filters-children').filter((i, d) -> d.getAttribute('data-parent') == parentId.toString())
    childDiv = searchMakeChildDiv($content, parentId) unless childDiv.length
    nochildDiv = $content.children('.search-filters-nochildren')
    nochildDiv.hide()
    $content.children('.search-filters-children').hide()
    childDiv.css('display', 'block')
    $li.siblings('li').removeClass('selected')
    $li.addClass('selected')
    false
  else
    true

$(document).on 'click', '.search-filters-category', (event) ->
  $('.search-filters-expansion-help').hide()

$(document).on 'click', '.search-filters-parents input', (event) ->
  $this = $(this)
  $li = $this.closest('li')
  $content = $li.closest('.search-topic-content')
  parentId = $li.data('parent-id')
  childDiv = $content.children('.search-filters-children').filter((i, d) -> d.getAttribute('data-parent') == parentId.toString())
  nochildDiv = $content.children('.search-filters-nochildren')
  if $this.prop('checked')
    childDiv = searchMakeChildDiv($content, parentId) unless childDiv.length
    nochildDiv.hide()
    $content.children('.search-filters-children').hide()
    childDiv.css('display', 'block')
    $li.siblings('li').removeClass('selected')
    $li.addClass('selected')
  else
    childDiv.hide()
    nochildDiv.css('display', 'block')
    $li.removeClass('selected')

searchMakeChildDiv = ($content, parentId) ->
  childDiv = $('<div />', class: 'search-filters-children', 'data-parent': parentId)
  childDiv.css('max-height': $(window).height() * 0.7)
  $content.append(childDiv)
  params = {topic_id: $content.closest('.search-topic').data('id'), parent: parentId}
  # function to load the data from the server a place it in the div
  func = -> $.get $('.search-form').attr('action') + "/child_questions?#{$.param(params)}", (data) ->
    $content.children('.search-filters-children-selected').filter((i, d) -> d.getAttribute('data-parent') == parentId.toString()).remove()
    childDiv.html($(data.html))
  # if this search doesn't have an id we need to wait until it's been saved first
  # use setTimeout to place this action on the form queue after the save function created by the other handler
  $form = $('.search-form')
  if $form.attr('action').match(/\d+$/)
    func()
  else
    setTimeout (-> $form.queue(-> func(); $form.dequeue())), 0
  childDiv

$(document).on 'click', '.search-topic-detail-link', (event) ->
  $(this).closest('.search-topic').children('.search-topic-detail').slideToggle()
  false

$(document).on 'click', '.search-topic-more', (event) ->
  searchShowPopup($(event.target).closest('.search-topic'), true)
  false

$(document).on 'click', 'a.search-topic-clear', (event) ->
  sfc = $(this).closest('.search-topic-main').children('.search-topic-content')
  inputs = sfc.find('input:checked')
  $(".search-filters-expansion-help").show()
  if inputs.length > 0
    inputs.prop('checked', false)
    sfc.children('.search-filters-children').hide()
    sfc.children('.search-filters-parents').find('li').removeClass('selected')
    sfc.children('.search-filters-nochildren').css('display', 'block')
    searchProcessPost(inputs)
  false

$(document).on 'click', 'a.search-topic-close', (event) ->
  $(event.target).closest('.search-topic-main').fadeOut('fast')
  false

$(document).on 'click', '#search-results-more', (event) ->
  searchLoadNextPage()

$(document).on 'click', '#search-controls .link', (event) ->
  $popup = $(this).next('.popup')
  if $popup.hasClass('refresh') and not $popup.hasClass('refreshed')
    $popup.addClass('refreshed')
    $popup.children('.popup-content').remove()
    $popup.children('.throbber').show()
    params = {}
    areaId = $('#search_area_id').attr('value')
    params['area_id'] = areaId if areaId
    $.get "#{$('.search-form').attr('action')}/#{$popup.data('src')}?#{$.param(params)}", (data) ->
      $popup.children('.throbber').hide()
      $popup.append(data.html)
      searchFixReturnTos()
  $popup.show()

$(document).on 'click', '#social-external-link .link', (event) ->
  $popup = $(this).next('.popup')
  $popup.show()

$(document).on 'click', '#social-external-link .close', (event) ->
  $(this).closest('.popup').hide()
  false

$(document).on 'click', '#search-controls .close', (event) ->
  $(this).closest('.popup').hide()
  false

searchUpdateBookmark = ->
  $('#search-bookmarks-link').toggleClass("selected", $('#search-bookmarked').is(':checked'))

$(document).on 'click', '#search-bookmarked', (event) ->
  searchUpdateBookmark()
  searchProcessPost($(this))

$(document).on 'click', '#search-bookmark-link', (event) ->
  $('#search-bookmarked').trigger('click')
  $('#search-bookmarks-popup').hide()
  false

# workaround for IE8 not passing label clicks to hidden inputs
$(document).on 'click', '.search-result-select label', (event) ->
  event.preventDefault()
  $("##{$(this).attr('for')}").trigger('click')

$(document).on 'click', '.search-result-select input', (event) ->
  $this = $(this)
  $li = $this.closest('li')
  $toolbar = $('#search-results-projects')
  selected = $this.prop('checked')
  $li.toggleClass('selected', selected)
  $selected = $('#search-results-list li.selected')
  count = $selected.length
  $message = $('#search-results-projects-message')
  $message.text($message.data(if count > 1 then 'other' else 'one').replace('%{count}', count))
  $compare = $('#search-results-projects-compare')
  compareUrl = $compare.data('url')
  if compareUrl
    params = XUtils.paramsHash(compareUrl)
    params['ids'] = ($.map $selected, (e) -> $(e).children('.search-result-select').children('.search-result-select-styling').children('input').prop('value')).join(',')
    $compare.prop('href', $compare.data('url').replace(/\?.*/, '?' + $.param(params)))
  $compare.removeAttr('href') unless compareUrl && count > 1
  $toolbar.find("button").prop('disabled', count < 1)
  if count > 0 then $toolbar.slideDown() else $toolbar.slideUp()
  if selected
    $window = $(window)
    $label = $this.next('label')
    $outline = $label.clone().css(position: 'fixed', left: $label.offset().left - $window.scrollLeft(), top: $label.offset().top - $window.scrollTop(), 'z-index': 10)
    $outline.appendTo($this.parent())
    $outline.animate(left: $toolbar.offset().left - $window.scrollLeft(), top: $toolbar.offset().top - $window.scrollTop(), 500, -> $(this).detach())
  true

$(window).on 'scroll', (event) ->
  searchCheckLoadNextPage()

$(document).on 'click', "#search-results-projects-add", (event) ->
  $ul = $('#search-results-projects ul')
  if $ul.children().length == 1
    $ul.closest('form').submit()
  else
    $ul.slideToggle()

# workaround for IE8 not passing label clicks to hidden inputs
$(document).on 'click', '#search-results-projects li label', (event) ->
  event.preventDefault()
  $("##{$(this).attr('for')}").trigger('click')

$(document).on 'click', '#search-results-projects li input', (event) ->
  # use setTimeout to ensure the input is updated before the form is submitted (IE8 workaround)
  $this = $(this)
  setTimeout (-> $this.closest('form').submit()), 0

$(document).on 'click', '#mps-search-section li input', (event) ->
  $('#search-sidebar-inner').hide()
  searchSwitchView('#results')

$(document).on 'click', '#search-results-toggle', (event) ->
  searchSwitchView('#results')

$(document).on 'click', '#search-topbar-toggle', (event) ->
  $('#search_text').val('')
  searchProcessPost($('#search_text'))

  if $('#search-topbar').length
    $search_queries = window.location.search.split("&")
    $realmName = $('#search-topbar')[0].dataset.realm
    if $realmName == 'Marsh' && $search_queries != undefined && $search_queries.length > 1
      $section = $search_queries[1].split("=")
      if $section[0] == 'section' && $section[1] == '146'
        window.location.href = "#{window.location.protocol}//#{window.location.host}/vendormatch/search#section=146"
      else
        searchSwitchView('best_sections', true)
    else
      searchSwitchView('best_sections', true)
  else  
    searchSwitchView('best_sections', true)  

searchUpdateForTopbar = ->
  has_topbar_terms = !!$('#search-topbar').has('input:checked').length
  $all = $('#search-all')
  searchUpdateResultsMin(true) if has_topbar_terms
  return if $all.hasClass('has_topbar_terms') == has_topbar_terms
  searchSlideViewElement($('#search-results-min'), has_topbar_terms)
  $all.toggleClass('has_topbar_terms', has_topbar_terms)

searchUpdateResultsMin = (loading = false) ->
  $cards = $('#search-results-min').find('.cards')
  $results = $cards.children('.results')
  $empty = $cards.children('.empty')
  $throbber = $cards.children('.throbber')
  $toggle = $('#search-results-toggle')
  $results.find('.carousel').trigger('endCarousel')
  $results.remove()
  if loading
    $empty.hide()
    $toggle.css(visibility: 'hidden')
    $throbber.show()
  else
    $ul = $('<ul />')
    $carousel = $('<div />', class: 'carousel').append($ul)
    $carouselWrapper = $('<div />', class: 'carousel_wrapper').append($carousel)
    $results = $('<div />', class: 'results').append($carouselWrapper)
    $lis = $('#search-results').children('form').children('ul').children('li').slice(0, 9)
    if $lis.length
      $lis.each (i, li) ->
        $li = $(li)
        $newLi = $('<li />')
        $div = $('<div />', class: "holder").appendTo($newLi)
        $div.append($li.find('.search-result-details').clone())
        $div.append($li.find('.search-result-logo').clone())
        $ul.append($newLi)
      $empty.hide()
      $throbber.hide()
      $cards.append($results)
      $carousel.jCarouselLite(auto: true, timeout: 3000, speed: 1000, start: 0, visible: $lis.length, swipe: false)
      $toggle.css(visibility: '')
    else
      $throbber.hide()
      $toggle.css(visibility: 'hidden')
      $empty.show()

searchSlideViewElement = ($elem, down, complete) ->
  $slider = $elem.children('.inner')
  $slider = $elem unless $slider.length
  start = -> $elem.css('display', 'block')
  always = -> $elem.css('display', ''); complete() if complete
  if down
    $slider.slideDown(start: start, always: always)
  else
    $slider.slideUp(start: start, always: always)
