recalcRfiContentNavHeight = () ->
  scrollY = $(window).scrollTop()
  navPosition = $("#profile-page-links nav").position();
  topNavHeight = $("#profile-page-links nav").height();
  navHeight = $("#rfi-table-contents-inner nav").height();
  if navPosition != undefined && navHeight != undefined && topNavHeight != undefined
    fixedPosition =  navPosition.top + topNavHeight - 90

    relativeAvailableHeight = $(window).height() - fixedPosition - 220
    fixedAvailableHeight = $(window).height() - 220
    windowHeightNotFixed = relativeAvailableHeight
    windowHeightFixed = fixedAvailableHeight
    if windowHeightNotFixed > navHeight
      windowHeightNotFixed = navHeight + 220
    if windowHeightFixed > navHeight
      windowHeightFixed = navHeight + 220

    hasFixedPositionClass = $("#rfi-table-contents").hasClass("fixed-position")

    if scrollY >  fixedPosition && !hasFixedPositionClass
      $("#rfi-table-contents-inner").height(windowHeightFixed);
      $("#rfi-table-contents").addClass("fixed-position")
    if scrollY <= fixedPosition && !hasFixedPositionClass
      $("#rfi-table-contents-inner").height(windowHeightNotFixed + scrollY);
    if scrollY <=  fixedPosition && hasFixedPositionClass
      $("#rfi-table-contents-inner").height(windowHeightNotFixed);
      $("#rfi-table-contents").removeClass("fixed-position")


$(document).ready ->
  
  recalcRfiContentNavHeight()
$(document).on "scroll", (e) ->
  recalcRfiContentNavHeight()

  