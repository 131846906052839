$(document).ready ->
  $('#modal-open a').on 'click', ->
    history.replaceState(null, null, "#close")
  $('#modal-warning-open a').on 'click', ->
    
    modal = document.getElementById("modal-warning-open");
    modal.style.display = "none";
  $('.upsellable.survey-summary-modal').on 'click', ->
    if (sessionStorage)
      sessionStorage.setItem("selectedModal", "survey-summary-modal");
    viewSurveySummaryModal()
    setTimeout (->
      $('#surveyable-title div.title-links .modal-dialog-content-title a.modal-dialog-close').focus()
      return
      ), 300
    setTimeout (->
      $('#project-surveyables .surveyable .title-links #modal-open.modal-dialog-container.upsellable-popup.wrappable .modal-dialog-content-title a.modal-dialog-close').focus()
      return
      ), 300
  $('.upsellable.rfx-modal').on 'click', ->
    if (sessionStorage)
      sessionStorage.setItem("selectedModal", "rfx-modal");
    viewRfxModal()
    setTimeout (->
      $('.modal-dialog-close').focus()
      return
      ), 300
  $('.ask-analyst-modal').on 'click', ->
    if (sessionStorage)
      sessionStorage.setItem("selectedModal", "ask-analyst-modal");
    askAnalystModal()
    setTimeout (->
      $('.modal-dialog-close').focus()
      return
      ), 300
  $('.upsellable.vendor-ia-modal').on 'click', ->
    $('.vendor-expl').show();
    $('.rfx-expl').hide();
    $('.ask-analyst-main').hide()
    $('.ask-analyst-subs').hide()
    setTimeout (->
      $('.modal-dialog-close').focus()
      return
      ), 300
  $('.upsellable.generic-modal').on 'click', ->
    if (sessionStorage)
      sessionStorage.setItem("selectedModal", "generic-modal");
    viewGenericModal()
    setTimeout (->
      $('.modal-dialog-close').focus()
      return
      ), 300
  $('.permissions-needed-modal').on 'click', ->
    $('.ask-analyst-main').hide()
    $('.ask-analyst-subs').hide()
    setTimeout (->
      $('.modal-dialog-close').focus()
      return
      ), 300
  $('.registration-needed-modal').on 'click', ->
    $('.ask-analyst-main').show()
    $('.ask-analyst-subs').hide()
    setTimeout (->
      $('.modal-dialog-close').focus()
      return
      ), 300
  $('.project-permissions-modal').on 'click', ->
    $('.ask-analyst-main').hide()
    $('.ask-analyst-subs').hide()
    setTimeout (->
      $('.modal-dialog-close').focus()
      return
      ), 300
  eventRefresh()

eventRefresh = ->
  if (sessionStorage && sessionStorage.getItem("selectedModal"))
    selectedModal = sessionStorage.getItem("selectedModal")
    if selectedModal=="survey-summary-modal"
      viewSurveySummaryModal()
    else if selectedModal=="rfx-modal"
      viewRfxModal()
    else if selectedModal=="ask-analyst-modal"
      askAnalystModal()
    else if selectedModal=="generic-modal"
      viewGenericModal()

viewSurveySummaryModal = ->
  $('.survey-summary-expl').show();
  $('.ask-an-analyst').hide();
  $('.contact-us').hide();
  $('.contact-mgr').hide();
  $('.for-users').hide();
  $('.vendor-expl').hide();
  $('.rfx-expl').hide();
  $('.ask-analyst-main').hide()
  $('.ask-analyst-subs').hide()

viewRfxModal = ->
  $('.survey-summary-expl').hide();
  $('.vendor-expl').hide();
  $('.for-users').hide();
  $('.ask-an-analyst').hide();
  $('.contact-us').hide();
  $('.contact-mgr').hide();
  $('.rfx-expl').show();
  $('.ask-analyst-main').hide()
  $('.ask-analyst-subs').hide()

askAnalystModal = ->
  $('.survey-summary-expl').hide();
  $('.ask-an-analyst').show();
  $('.ask-analyst-subs').show()
  $('.for-users').hide();
  $('.vendor-expl').hide();
  $('.rfx-expl').hide();
  $('.contact-us').hide();

viewGenericModal   = ->
  $('.survey-summary-expl').hide();
  $('.vendor-expl').show();
  $('.for-users').show();
  $('.ask-an-analyst').hide();
  $('.rfx-expl').hide();
  $('.ask-analyst-main').hide()
  $('.ask-analyst-subs').hide()