loadScript = (onload) ->
  script = document.getElementById('echarts-script')
  if !script
    script = document.createElement('script')
    script.id = 'echarts-script'
    script.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/echarts/4.2.1/echarts-en.min.js')
    script.onloadScripts = []
    script.onload = ->
      ls() for ls in this.onloadScripts
      this.onloadScripts = null
    document.body.appendChild(script)
  if script.onloadScripts
    script.onloadScripts.push onload
  else
    onload()

install = (elem, option, callback) ->
  loadScript ->
    chart = echarts.getInstanceByDom(elem)
    if !chart
      chart = echarts.init(elem)
      $(window).on 'resize', -> chart.resize()
    chart.setOption(option)
    callback(chart) if callback

window.celentRadar = (elem, template, config, callback) ->
  install elem, {
    angleAxis: {
      axisLabel: {
        backgroundColor: '#54616e',
        color: '#fff',
        formatter: (val) -> template.categories[val],
        fontFamily: 'Montserrat, "Source Sans Pro", sans-serif'
        padding: 10,
      },
      axisLine: {show: false},
      axisTick: {show: false},
      interval: 0.5,
      max: template.categories.length,
      min: 0,
      splitLine: {
        lineStyle: {
          color: ['#0000', '#fffa'],
          width: 4,
        },
      },
      startAngle: 90 - 180 / template.categories.length,
      type: 'value',
    },
    legend: {
      data: [template.regionsTitle, template.regions.map((v) -> v[0])...],
      selectedMode: false,
      x: 'center',
      y: 'bottom',
    },
    polar: {
      id: 'polar',
      center: ['50%', '45%'],
      radius: '80%',
    },
    radiusAxis: {
      axisLabel: {show: false},
      axisLine: {show: false},
      axisTick: {show: false},
      interval: 1,
      max: 3,
      min: 0,
      splitArea: {
        areaStyle: {
          color: template.regions.map((a) -> a[1]),
          shadowBlur: 8,
          shadowColor: '#0004',
        },
        show: true,
      },
    },
    series: [
      {
        animation: false,
        coordinateSystem: 'polar',
        data: config.data,
        id: 'data',
        itemStyle: {
          borderColor: '#555',
          borderWidth: 2,
          color: '#ccc',
          opacity: 1,
        },
        label: {
          color: '#000',
          fontFamily: '"Source Sans Pro", sans-serif',
          fontSize: 12,
          formatter: (o) -> o.data.info.number,
          show: true,
        },
        symbol: 'pin'
        symbolSize: 32,
        type: 'scatter',
      },
      {
        coordinateSystem: 'none',
        data: [],
        itemStyle: {
          color: '#0000',
        },
        name: template.regionsTitle,
        type: 'custom',
      },
      (template.regions.map (v) ->
        {
          coordinateSystem: 'none',
          data: [],
          itemStyle: {
            color: v[1],
          },
          name: v[0],
          type: 'custom',
        }
      )...
    ],
    tooltip: {
      formatter: (o) -> [o.data.info.name, o.data.info.message].filter((s) -> s).join(' - ')
      textStyle: {
        fontFamily: 'Montserrat, "Source Sans Pro", sans-serif',
      },
    },
  }, callback

$(document).on 'ready', ->
  $radar = $('#radar-chart')
  return unless $radar.length
  template = JSON.parse($radar.attr('data-template'))
  config = JSON.parse($radar.attr('data-config'))
  celentRadar $radar.get(0), template, config, (chart) ->
    chart.on 'click', (e) -> window.open(e.data.info.url, '_blank')
