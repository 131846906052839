$(document).on 'change', '#award_nomination .award_nomination_document_field input[type="file"]', ->
  $field = $(this).closest('.award_nomination_document_field')
  $field.children('img.attach-existing').hide()
  $field.children('img.attach-new').show()
  $field.children('input[type="file"]').hide()
  $field.children('button.clear').show()

$(document).on 'click', '#award_nomination button.clear', ->
  $field = $(this).closest('.award_nomination_document_field')
  $field.children('input.remove').prop('disabled', false)
  $field.children('img.attach-existing').hide()
  $field.children('img.attach-new').hide()
  $file = $field.children('input[type="file"]')
  $newFile = $file.val("").clone(true)
  $file.replaceWith($newFile)
  $newFile.show()
  $field.children('button.clear').hide()
