$(document).ready ->
  frontpageSetupCover();
  
  

frontpageSetupCover = ->
  $coverList = $('#frontpage-cover ul')
  if $coverList.length
    setInterval ->
      $first = $coverList.children().first()
      $active = $coverList.children('.active')
      $active = $first unless $active.length
      $next = $active.next()
      $next = $first unless $next.length
      $active.css('z-index', 3)
      $next.css('z-index', 2)
      $active.fadeOut 1000, ->
        $active.css('z-index', 1).show().removeClass('active')
        $next.addClass('active')
    , 7000
lastScrollTop = 0;
timer = null;
navAction = $('#celent-mobile-nav-btn')
body = $("#celent-dashboard-base")

$(window).on 'scroll', (event) ->
  if navAction.length and body.length and body.hasClass('profile-nav-show') == false
    if timer != null
      clearTimeout(timer)
    timer = setTimeout -> 
      navAction.removeClass('scroll-down')
    , 150
    st = $(this).scrollTop();
    if st > lastScrollTop
      if navAction.hasClass('scroll-down') == false
        navAction.addClass('scroll-down')
    else
      if navAction.hasClass('scroll-down') == true
        navAction.removeClass('scroll-down')
    lastScrollTop = st;

$(document).on 'click', '#celent-mobile-nav-btn', ->
  $("#celent-dashboard-base").toggleClass('profile-nav-show')
  $(".celent-nav-close").toggleClass('hidden')

$(document).on 'click', '#product_visualizer_consent', ->
  $form = $(this)[0].closest('form')
  $consented = $(this)[0].checked
  $solution_id = $form.id.split("_")[2]
  $.ajax({
    type:"POST",
    dataType:"script",
    url:"/solutions/#{$solution_id}/consent",
    data:  new URLSearchParams({'consented': $consented, "consent_type": "visualizer_consent"}).toString(),
  })

$(document).on 'click', '#aside-user-profile', -> 
  $(this).children()[1].classList.toggle("hidden")
  $(this).children()[2].classList.toggle('hidden')
  $(this).parent().next().toggleClass('hidden')

$(document).on 'click', '#product_vendor_profile_consent', ->
  $form = $(this)[0].closest('form')
  $consented = $(this)[0].checked
  $solution_id = $form.id.split("_")[2]
  $.ajax({
    type:"POST",
    dataType:"script",
    url:"/solutions/#{$solution_id}/consent",
    data:  new URLSearchParams({'consented': $consented, "consent_type": "vendor_profile_consent"}).toString(),
  })
