$(document).on 'click', '#admin-topic .survey-name input', ->
  $this = $(this)
  $content = $this.closest('.survey-name').next('.survey-content')
  if $this.is(':checked') then $content.slideDown() else $content.slideUp()

$(document).on 'change', '#admin-topic #topic_domain_shared_list_id', ->
  $('#admin-topic-custom_string-field').css('display', if $(this).val() then 'none' else 'block')

$(document).on 'change', '#admin-topic .survey_group_select', ->
  $this = $(this)
  $fieldset = $this.closest('fieldset')
  $edit_form = $fieldset.closest('form.edit_topic')
  clearSelect($fieldset.find('.single_required_topic_select'))
  survey_id = $fieldset.data('survey_id')
  survey_group_id = $this.val()
  m = /\d+$/.exec($edit_form.attr('action'))
  topic_id = m && m[0]
  $.get '/admin/topics/single_required_topic_select_options?' + $.param({survey_id: survey_id, survey_group_id: survey_group_id, topic_id: topic_id}), null, null, 'script' if survey_group_id

$(document).on 'change', '#admin-topic .single_required_topic_select', ->
  $this = $(this)
  $fieldset = $this.closest('fieldset')
  clearSelect($fieldset.find('.single_required_question_select'))
  survey_id = $fieldset.data('survey_id')
  topic_id = $this.val()
  $.get '/admin/topics/single_required_question_select_options?' + $.param({survey_id: survey_id, topic_id: topic_id}), null, null, 'script' if topic_id

$(document).on 'click', '#admin-topic a.scoring-name', ->
  $(this).next('.scoring-content').slideToggle()

clearSelect = ($select) ->
  $select.val('')
  $select.children('option').slice(1).remove()
