$(document).on 'mouseover', '.research-nav-trigger', ->
  $('#research-nav').data('triggered', true).trigger('changeData')

$(document).on 'mouseout', '.research-nav-trigger', ->
  $('#research-nav').data('triggered', false).trigger('changeData')

$(document).on 'mouseover', '#research-nav', ->
  $('#research-nav').data('over', true).trigger('changeData')

$(document).on 'mouseout', '#research-nav', ->
  $('#research-nav').data('over', false).trigger('changeData')

$(document).on 'changeData', '#research-nav', ->
  $this = $(this)
  $collapse = $this.find('.MuiCollapse')
  openId = $this.data('openId') || 0
  if $this.data('triggered') || $this.data('over')
    $this.data('openId', openId + 1)
    $collapse.addClass('entered')
  else
    setTimeout ->
      if openId == $this.data('openId')
        $collapse.removeClass('entered')
    , 1000

$(document).on 'click', '.drawer-menu-trigger', ->
  $('#drawer-menu').show()

$(document).on 'click', '.drawer-menu-closer', ->
  $('#drawer-menu').hide()
