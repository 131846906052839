$(document).on 'change', '#admin-award_nomination .award_nomination_document_field input[type="file"]', ->
  $field = $(this).closest('.award_nomination_document_field')
  $field.children('img.attach-existing').hide()
  $field.children('img.attach-new').show()
  $field.children('input[type="file"]').hide()
  $field.children('button.clear').show()

$(document).on 'click', '#admin-award_nomination button.clear', ->
  $field = $(this).closest('.award_nomination_document_field')
  $field.children('input.remove').prop('disabled', false)
  $field.children('img.attach-existing').hide()
  $field.children('img.attach-new').hide()
  $file = $field.children('input[type="file"]')
  $newFile = $file.val("").clone(true)
  $file.replaceWith($newFile)
  $newFile.show()
  $field.children('button.clear').hide()

# award_nomination_other_award_category
$(document).on 'ready', ->
  if $("select#award_nomination_award_category_id option:selected").text() == "Other" || $("select#award_nomination_multi_secondary_award_category option:selected").text().indexOf("Other") != -1
    $("#award_nomination_other_award_category").parent().show()
  else
    $("#award_nomination_other_award_category").parent().hide()
    $("#award_nomination_other_award_category").val("")

$(document).on 'change', 'select#award_nomination_award_category_id', ->
  if $("select#award_nomination_award_category_id option:selected").text() == "Other" || $("select#award_nomination_multi_secondary_award_category option:selected").text().indexOf("Other") != -1
    $("#award_nomination_other_award_category").parent().show()
  else
    $("#award_nomination_other_award_category").parent().hide()
    $("#award_nomination_other_award_category").val("")
  
$(document).on 'change', 'select#award_nomination_multi_secondary_award_category', ->
  if $("select#award_nomination_award_category_id option:selected").text() == "Other" || $("select#award_nomination_multi_secondary_award_category option:selected").text().indexOf("Other") != -1
    $("#award_nomination_other_award_category").parent().show()
  else
    $("#award_nomination_other_award_category").parent().hide()
    $("#award_nomination_other_award_category").val("")

#award_nomination_other_fi_type
$(document).on 'ready', ->
  if $("select#award_nomination_fi_type option:selected").text() == "Other"
    $("#award_nomination_other_fi_type").parent().show()
  else
    $("#award_nomination_other_fi_type").parent().hide()
    $("#award_nomination_other_fi_type").val("")

$(document).on 'change', 'select#award_nomination_fi_type', ->
  if $("select#award_nomination_fi_type option:selected").text() == "Other"
    $("#award_nomination_other_fi_type").parent().show()
  else
    $("#award_nomination_other_fi_type").parent().hide()
    $("#award_nomination_other_fi_type").val("")


#award_nomination_other_benefits
$(document).on 'ready', ->
  if $("select#award_nomination_value_chain_impact").val() == "Other"
    $("#award_nomination_other_benefits").parent().show()
  else
    $("#award_nomination_other_benefits").parent().hide()
    $("#award_nomination_other_benefits").val("")

$(document).on 'change', 'select#award_nomination_value_chain_impact', ->
  if $("select#award_nomination_value_chain_impact").val() == "Other"
    $("#award_nomination_other_benefits").parent().show()
  else
    $("#award_nomination_other_benefits").parent().hide()
    $("#award_nomination_other_benefits").val("")


#award_nomination_other_business_line

$(document).on 'ready', ->
  if $("select#award_nomination_multi_business_line") != null
    if "Other" in $("select#award_nomination_multi_business_line").val()
      $("#award_nomination_other_business_line").parent().show()
    else
      $("#award_nomination_other_business_line").parent().hide()
      $("#award_nomination_other_business_line").val("")

$(document).on 'change', 'select#award_nomination_multi_business_line', ->
  if "Other" in $("select#award_nomination_multi_business_line").val()
    $("#award_nomination_other_business_line").parent().show()
  else
    $("#award_nomination_other_business_line").parent().hide()
    $("#award_nomination_other_business_line").val("")

#award_nomination_other_applications_impacted
$(document).on 'ready', ->
  if "Other" in $("select#award_nomination_applications_impacted").val()
    $("#award_nomination_other_applications_impacted").parent().show()
  else
    $("#award_nomination_other_applications_impacted").parent().hide()
    $("#award_nomination_other_applications_impacted").val("")

$(document).on 'change', 'select#award_nomination_applications_impacted', ->
  if "Other" in $("select#award_nomination_applications_impacted").val()
    $("#award_nomination_other_applications_impacted").parent().show()
  else
    $("#award_nomination_other_applications_impacted").parent().hide()
    $("#award_nomination_other_applications_impacted").val("")

#award_nomination_other_technology_used
$(document).on 'ready', ->
  if "Other" in $("select#award_nomination_technology_used").val()
    $("#award_nomination_other_technology_used").parent().show()
  else
    $("#award_nomination_other_technology_used").parent().hide()
    $("#award_nomination_other_technology_used").val("")

$(document).on 'change', 'select#award_nomination_technology_used', ->
  if "Other" in $("select#award_nomination_technology_used").val()
    $("#award_nomination_other_technology_used").parent().show()
  else
    $("#award_nomination_other_technology_used").parent().hide()
    $("#award_nomination_other_technology_used").val("")

#award_nomination_other_value_chain_impact
$(document).on 'ready', ->
  if "Other" in $("select#award_nomination_value_chain_impact").val()
    $("#award_nomination_other_value_chain_impact").parent().show()
  else
    $("#award_nomination_other_value_chain_impact").parent().hide()
    $("#award_nomination_other_value_chain_impact").val("")

$(document).on 'change', 'select#award_nomination_value_chain_impact', ->
  if "Other" in $("select#award_nomination_value_chain_impact").val()
    $("#award_nomination_other_value_chain_impact").parent().show()
  else
    $("#award_nomination_other_value_chain_impact").parent().hide()
    $("#award_nomination_other_value_chain_impact").val("")

addOptionFiSize = (value, text) ->
  $('#award_nomination_fi_size').append($("<option></option>").attr("value", value).text(text));

refreshOptionsFiSize = (options) ->
  $('#award_nomination_fi_size').children().remove().end()
  for oneOption in options
    do (oneOption) ->
      addOptionFiSize(oneOption.value,oneOption.text)

# x=AwardNomination.find(105593194)
# x.class.fi_size_h.select{|key| x.fi_size_by_award_type.include?(key)}.map{|key,value| "{value:#{key},text:#{value} }"}
$(document).on 'change', 'select#award_nomination_fi_type', ->
  if $("#award_nomination_award_type_name").val() == "Model Risk Manager"
    if $("select#award_nomination_fi_type").val() == "commercial_banks"
# Change the select options
      options =  [{value:"tier_1_bank_500b",text:"Tier 1 - Bank total assets US $500 billion and more" },
        {value:"tier_2_bank_100b",text:"Tier 2 - Bank total assets US $100 billion to US $499 billion" },
        {value:"tier_3_bank_50b", text:"Tier 3 - Bank total assets US $50 billion to US $99 billion" },
        {value:"tier_4_bank_20b", text:"Tier 4 - Bank total assets US $20 billion to US $49 billion" },
        {value:"tier_5_bank_1b", text: "Tier 5 - Bank total assets US $1 billion to US $19 billion" },
        {value:"tier_6_bank_under_1b", text:"Tier 6 - Bank total assets under US $1 billion" }]
      refreshOptionsFiSize(options)
    else if $("select#award_nomination_fi_type").val() == "investment_banks"
# Change the select options
      options = [ {value:"tier_1_bulge_bracket", text:"Tier 1 (Bulge Bracket / Full Service)"},
        {value:"tier_2_regional", text: "Tier 2 (Regional or Product leader)"},
        {value:"tier_3_middle_market", text: "Tier 3 (Middle Market & Boutique)"}
      ]
      refreshOptionsFiSize(options)
    else if $("select#award_nomination_fi_type").val() == "asset_management"
# Change the select options
      options = [
        {value:"tier_1_aum",text: "Tier 1 AUM >$1 trillion"},
        {value:"tier_2_aum",text: "Tier 2  AUM $100 billion - $1 trillion"},
        {value:"tier_3_aum",text: "Tier 3 AUM <$100 billion"}
      ]
      refreshOptionsFiSize(options)
    else if $("select#award_nomination_fi_type").val() == "hedge_funds" || $("select#award_nomination_fi_type").val() == "wealth_manager"
# Change the select options
      options = [
        {value:"tier_1_large_aum",text: "Tier 1 / Large AUM >$10 billion"},
        {value:"tier_2_large_aum",text: "Tier 2 / Medium AUM $1 billion – 10 billion"},
        {value:"tier_3_large_aum",text: "Tier 3 / Small AUM <$1 billion"}
      ]
      refreshOptionsFiSize(options)
    else if $("select#award_nomination_fi_type").val() == "insurance"
# Change the select options
      options =  [{value:"tier_1_insurers_5b",text:"Tier 1 - Insurers US $5 billion in direct written premium and more" },
        {value:"tier_2_insurers_1_49b",text:"Tier 2 - Insurers with US $1 billion to US $4.9 billion in direct written premium" },
        {value:"tier_3_insurers_500_999m",text:"Tier 3 - Insurers with US $500 million to US $999 million in direct written premium" },
        {value:"tier_4_insurers_100_499m",text:"Tier 4 - Insurers with US $100 million to US $499 million in direct written premium" },
        {value:"tier_5_insurers_under_100m",text:"Tier 5 - Insurers under US $100 million in direct written premium" }]
      refreshOptionsFiSize(options)
    else if $("select#award_nomination_fi_type").val() == "other_model_risk_manager"
# Change the select options
      options = [{value: "other", text: "Other"}]
      refreshOptionsFiSize(options)
    else
# Change the select options
  else if $("#award_nomination_award_type_name").val() == "Model Buy Side"
    if $("select#award_nomination_fi_type").val() == "hedge_fund"
      options =  [{value: "tier_1_large_aum",text:"Tier 1 / Large AUM >$10 billion"},
        {value: "tier_2_large_aum",text:"Tier 2 / Medium AUM $1 billion – 10 billion"},
        {value: "tier_3_large_aum",text:"Tier 3 / Small AUM <$1 billion"}]
      refreshOptionsFiSize(options)
    else
      options =  [{value: "tier_1_aum",text:"Tier 1 AUM >$1 trillion"},
        {value: "tier_2_aum",text:"Tier 2  AUM $100 billion - $1 trillion"},
        {value: "tier_3_aum",text:"Tier 3 AUM <$100 billion"}]
      refreshOptionsFiSize(options)
  else

$(document).on 'change', '.add-a-file-input', ->
  for i in [0...($(".add-a-file-input").length)]
    for j in [0...($(".add-a-file-input")[i].files.length)]
      fsize = Math.round($(".add-a-file-input")[i].files[j].size / 1024);
      if fsize >= 51200
        alert("File size upload is limited to 50MB, please select another file.");
        $(".add-a-file-input-clear")[i].click()