$(document).ready ->
  $table = $('#admin-survey_topic-table')
  return unless $table.length
  elem = $table.find('.scroll_to')[0]
  elem.scrollIntoView() if elem

$(document).on 'click', '.period-select', ->
  $('#date-range-from').val("");
  $('#date-range-to').val("");

$(document).on 'click', '#date-range-from', ->
  $('#date-range-to').children('input[type="date"]').attr("placeholder", "today");

$(document).on 'click', '.audit-accordion', ->
  $('.audit-accordion').toggleClass "audit-accordion-active"
  content = $('.audit-accordion').next()
  if $('.audit-accordion').hasClass("audit-accordion-active") then content.css('display', 'flex') else content.css('display', 'none')

$(document).on 'click', '.interaction-accordion', ->
  $('.interaction-accordion').toggleClass "interaction-accordion-active"
  content = $('.interaction-accordion').next()
  if $('.interaction-accordion').hasClass("interaction-accordion-active") then content.css('display', 'flex') else content.css('display', 'none')

            
       
